import {Avatar, Rating, Checkbox} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";

export default function SuperCoursesCardDashboard(props) {
    const {data, handleCheckBoxClicked, handleMoveIconClicked} = props;
    const imageRef = useRef();
    const getRatingStars = (count) => {
        return <Rating size="small" readOnly precision={0.5} defaultValue={count} />;
    };

    useEffect(() => {
        function adjustImageHeight() {
            const image = imageRef.current;
            if (image) {
                const width = image.offsetWidth;
                const height = (width / 451) * 316;
                image.style.height = `${height}px`;
            }
        }
        adjustImageHeight();
        window.addEventListener("resize", adjustImageHeight);
        return () => {
            window.removeEventListener("resize", adjustImageHeight);
        };
    }, [data.id]);

    return (
        <div className="w-1/5 xl:w-1/3 lg:w-1/2 sm:w-1/1">
            <div className="relative">
                <img
                    className="rounded-8 w-1/1"
                    src={data?.course_thumbnail_image ?? ""}
                    alt="courses"
                    style={{
                        objectFit: "cover",
                        height: "200px",
                        width: "100%",
                    }}
                    ref={imageRef}
                />
                {props?.showRightTopCheckBox ? (
                    <button className="absolute-button">
                        <span className="d-flex items-center justify-center size-25 bg-white shadow-1">
                            <Checkbox
                                inputProps={{"aria-label": "controlled"}}
                                sx={{"& .MuiSvgIcon-root": {fontSize: 35}}}
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                checked={data?.is_popular_badge ?? false}
                                onClick={(event) => handleCheckBoxClicked(event.target.checked, data)}
                            />
                        </span>
                    </button>
                ) : null}
            </div>

            <div className="pt-15">
                <div className="d-flex y-gap-10 justify-between items-center flex-wrap">
                    <div className="d-flex align-items-center text-14 lh-1">
                        <Avatar
                            src={data?.seller_obj?.user_profile_image ?? ""}
                            alt={`${data?.seller_obj?.user_first_name} ${data?.seller_obj?.user_last_name}`}
                        />
                        &nbsp;
                        <Link
                            className="linkCustom"
                            // to={`/instructors/${data?.seller_obj?.slug_name}`}
                        >
                            {`${data?.seller_obj?.user_first_name ?? ""} ${data?.seller_obj?.user_last_name ?? ""}`}
                        </Link>
                    </div>

                    <div className="d-flex items-center">
                        <div className="text-14 lh-1 text-yellow-1">
                            ( {data?.ratings_obj?.total_rating ?? 0} )&nbsp;
                        </div>
                        <div className="d-flex x-gap-5 items-center">
                            {getRatingStars(data?.ratings_obj?.total_rating ?? 0)}
                        </div>
                    </div>
                </div>

                <h3 className="text-16 fw-500 lh-15 mt-10">
                    <Link
                        // to={
                        // 	isBookmarkPage
                        // 		? `/courses/${data.slug_name}`
                        // 		: `${data.slug_name}`
                        // }
                        className="col text-purple-1"
                    >
                        {data.title}
                    </Link>
                </h3>
                <div className="d-flex y-gap-10 justify-between items-center mt-10 flex-wrap">
                    <div>
                        Chapters: <span className="text-dark-1">{data?.chapters_count ?? 0}</span>
                    </div>
                    <div className="text-line-through">${data.course_price}</div>
                </div>
                <div className="d-flex y-gap-5 justify-between items-center flex-wrap">
                    <div>
                        Lessons: <span className="text-dark-1">{data?.lesson_count ?? 0}</span>
                    </div>

                    {!data.is_course_free ? (
                        <>
                            <div className="text-dark-1">${data.sale_price}</div>
                        </>
                    ) : (
                        <>
                            <div className="text-dark-1">Free</div>
                        </>
                    )}
                </div>
            </div>
            {props?.showMoveLeftRightButtons ? (
                <div className="d-flex x-gap-20 items-center justify-end pt-20">
                    {/* Conditionally render the left arrow button */}
                    {!props?.isFirst && (
                        <div className="col-auto">
                            <button
                                onClick={() => handleMoveIconClicked("moveup", data)}
                                className="button -outline-dark-5 size-50 rounded-full d-flex justify-center items-center js-prev"
                            >
                                <i className="icon icon-arrow-left text-24"></i>
                            </button>
                        </div>
                    )}

                    {/* Conditionally render the right arrow button */}
                    {!props?.isLast && (
                        <div className="col-auto">
                            <button
                                onClick={() => handleMoveIconClicked("movedown", data)}
                                className="button -outline-dark-5 size-50 rounded-full d-flex justify-center items-center js-next"
                            >
                                <i className="icon icon-arrow-right text-24"></i>
                            </button>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
}
