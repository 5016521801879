import React, {useCallback, useEffect, useState} from "react";
import {Avatar, Button, Pagination, Typography, useMediaQuery} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Add} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import AddSuperCategory from "./AddSuperCategory";
import {
    deleteSuperCourseCategoryAction,
    getSuperCategories,
} from "../../../../redux/action/superAdminActions/superCategoryActions";
import {Popconfirm} from "antd";
import {useSnackbar} from "notistack";
import {commonDashboardHeading, commonDashboardSubHeading} from "../../../../components/common/DashboardElements";
import {LoggedInFooter} from "../../../../components/layout/footers/LoggedInFooter";
import {debounce} from "lodash";
import SuperCategoriesFilterAndSortingArea from "./SuperCategoriesFilterAndSortingArea";
import {CustomNoDataComponent} from "../../../../components/common/CustomNoDataComponent";

export default function SuperCategories() {
    const dispatch = useDispatch();
    const [openAddForm, setOpenAddForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const {enqueueSnackbar} = useSnackbar();
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState([]);

    const isMobile = useMediaQuery("(max-width:600px)");
    const isTablet = useMediaQuery("(max-width:690px)");

    const {
        data: Categories,
        isLoading,
        isSuccess,
        meta_data,
    } = useSelector((state) => state.superAdmin.superCategories);

    useEffect(() => {
        if (!localStorage.getItem("beauty_super_key")) {
            window.location.replace("login");
        }
    }, []);

    useEffect(() => {
        !isSuccess && dispatch(getSuperCategories());
    }, [dispatch, isSuccess]);

    const bodyForGetApi = () => {
        let body = {
            page: meta_data?.current ?? 1,
            search: search,
            ordering: sortBy[0],
        };
        return body;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearchDebounced = useCallback(
        debounce((value, sortBy) => {
            let body = {
                search: value,
                page: 1,
                ordering: sortBy[0],
            };
            dispatch(getSuperCategories(body));
        }, 500),
        [debounce]
    );

    const handleSearchChange = (e) => {
        const {value} = e.target;
        setSearch(value);
        handleSearchDebounced(value, sortBy);
    };

    const handleDelete = (categoryId) => {
        dispatch(
            deleteSuperCourseCategoryAction(categoryId, {
                onSuccess: (success) => {
                    enqueueSnackbar("Category Deleted Successfully...", {
                        variant: "success",
                    });
                    const bodyForApi = bodyForGetApi();
                    dispatch(getSuperCategories(bodyForApi));
                },
            })
        );
    };

    const handleSorting = (item) => {
        const tempBody = bodyForGetApi();
        const body = {
            ...tempBody,
            ordering: item,
        };
        dispatch(getSuperCategories(body));
    };

    const handlePageChange = (value) => {
        let tempBody = bodyForGetApi();
        let body = {
            ...tempBody,
            page: value,
        };
        dispatch(getSuperCategories(body));
    };

    const fullColumns = [
        {
            field: "image",
            width: 150,
            sortable: false,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {"Image"}
                </Typography>
            ),
            renderCell: (params) => {
                return (
                    <div>
                        <Avatar
                            variant="square"
                            style={{width: "100px", height: "60px", objectFit: "contain"}}
                            src={params.row.category_icon}
                            alt="course-category"
                        />
                    </div>
                );
            },
        },
        {
            field: "name",
            width: 200,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {"Category"}
                </Typography>
            ),
            renderCell: (params) => {
                return (
                    <div>
                        <b>{params?.row?.name ?? "--"}</b>
                    </div>
                );
            },
        },
        {
            field: "number_of_sub_categories",
            width: 50,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {"Total Sub Categories"}
                </Typography>
            ),
            renderCell: (params) => {
                return <div>{params?.row?.sub_category?.length ?? 0}</div>;
            },
        },
        {
            field: "number_of_course",
            width: 50,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {"Total Courses"}
                </Typography>
            ),
            renderCell: (params) => {
                return <div>{params?.row?.available_course_count ?? 0}</div>;
            },
        },
        {
            field: "actions",
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {"Actions"}
                </Typography>
            ),
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-end">
                        <div
                            className="icon-edit"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                setSelectedCategory(params.row);
                                setOpenEditForm(true);
                            }}
                        ></div>
                        &emsp;
                        <Popconfirm
                            title="Are you sure to delete this category?"
                            description={
                                <div>
                                    <p>All the related details will be deleted.</p>
                                    {params?.row?.sub_category?.length > 0 && (
                                        <>
                                            <span>Sub Categories:</span>&nbsp;
                                            <b>{params?.row?.sub_category?.length ?? 0}</b>
                                            <br />
                                        </>
                                    )}
                                    {params?.row?.available_course_count > 0 && (
                                        <>
                                            <span>Active Educator Courses:</span>&nbsp;
                                            <b>{params?.row?.available_course_count ?? 0}</b>
                                            <br />
                                        </>
                                    )}
                                    {params?.row?.available_deactive_course_count > 0 && (
                                        <>
                                            <span>Inactive Educator Courses:</span>&nbsp;
                                            <b>{params?.row?.available_deactive_course_count ?? 0}</b>
                                            <br />
                                        </>
                                    )}
                                    {params?.row?.active_seller_enrolled_course_count > 0 && (
                                        <>
                                            <span>Active Educator Enrolled Students:</span>&nbsp;
                                            <b>{params?.row?.active_seller_enrolled_course_count ?? 0}</b>
                                            <br />
                                        </>
                                    )}
                                    {params?.row?.inactive_seller_enrolled_course_count > 0 && (
                                        <>
                                            <span>Inactive Educator Enrolled Students:</span>&nbsp;
                                            <b>{params?.row?.inactive_seller_enrolled_course_count ?? 0}</b>
                                            <br />
                                        </>
                                    )}
                                </div>
                            }
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDelete(params.row.id)}
                        >
                            <div className="icon-bin" style={{cursor: "pointer"}}></div>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    // Reduced columns for mobile and tablet views
    const reducedColumns = [
        {
            field: "name",
            width: 200,
            sortable: false,
            flex: 1,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {"Category"}
                </Typography>
            ),
            renderCell: (params) => <b>{params?.row?.name ?? "--"}</b>,
        },
        {
            field: "actions",
            sortable: false,
            renderHeader: () => (
                <Typography variant="h6" className="text-purple-1">
                    {"Actions"}
                </Typography>
            ),
            renderCell: (params) => (
                <div className="d-flex justify-content-end">
                    <div
                        className="icon-edit"
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setSelectedCategory(params.row);
                            setOpenEditForm(true);
                        }}
                    ></div>
                    &emsp;
                    <Popconfirm
                        title="Are you sure to delete this category?"
                        onConfirm={() => handleDelete(params.row.id)}
                    >
                        <div className="icon-bin" style={{cursor: "pointer"}}></div>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const columns = isMobile || isTablet ? reducedColumns : fullColumns;

    const propsForAddForm = {
        open: openAddForm,
        setOpen: setOpenAddForm,
        type: "ADD",
        bodyForGetApi,
    };

    const propsForEditForm = {
        open: openEditForm,
        setOpen: setOpenEditForm,
        type: "EDIT",
        selectedCategory,
        bodyForGetApi,
    };

    const propsForFilterAndSortingComponent = {
        loading: isLoading,
        sortBy,
        setSortBy,
        handleSorting,
    };

    return (
        <div className="dashboard__main">
            {openAddForm && <AddSuperCategory {...propsForAddForm} />}
            {openEditForm && <AddSuperCategory {...propsForEditForm} />}
            <div className="dashboard__content bg-light-4" style={{paddingBottom: "0px"}}>
                <div className="row pb-30">
                    <div className="col-auto">
                        {commonDashboardHeading("Categories")}
                        {commonDashboardSubHeading("Welcome to the Categories Section.")}
                    </div>
                    <div className={`col-auto ${isMobile || isTablet ? "me-auto" : "ms-auto"}`}>
                        <div
                            style={{
                                position: "relative",
                                display: "inline-block",
                                width: "auto",
                            }}
                        >
                            <div
                                className="icon text-dark-1"
                                style={{
                                    position: "absolute",
                                    left: "5px",
                                    top: "56%",
                                    transform: "translateY(-50%)",
                                }}
                            >
                                <i className="icon-search text-light-1 text-18"></i>
                            </div>
                            <input
                                type="text"
                                placeholder="Type to search..."
                                style={{
                                    paddingLeft: "30px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    border: "1px solid grey",
                                    borderRadius: "20px",
                                    width: "300px",
                                }}
                                value={search}
                                onChange={(e) => handleSearchChange(e)}
                            />
                        </div>
                    </div>
                    <div className="col-auto">
                        <Button
                            className="button py-10 px-10 -dark-1 text-white -dark-button-white col-12"
                            startIcon={<Add />}
                            onClick={() => setOpenAddForm(true)}
                        >
                            Add Category
                        </Button>
                    </div>
                </div>

                <SuperCategoriesFilterAndSortingArea {...propsForFilterAndSortingComponent} />

                {/*Table Starts here */}
                <div
                    style={{
                        width: "100%",
                        overflowY: "scroll",
                        height: "calc(98vh - 300px)",
                    }}
                >
                    <DataGrid
                        rows={Categories}
                        columns={columns}
                        rowHeight={100}
                        showCellVerticalBorder
                        disableColumnMenu
                        disableRowSelectionOnClick={true}
                        disableColumnSelector
                        disableFocus
                        loading={isLoading}
                        sx={{
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#E5F0FD",
                            },
                        }}
                        hideFooter
                        slots={{
                            noRowsOverlay: CustomNoDataComponent,
                        }}
                    />
                </div>
                {/* Pagination Starts here */}
                <div className="row justify-center pt-30 lg:pt-50">
                    <div className="col-auto">
                        <div className="pagination -buttons d-flex justify-content-center">
                            <Pagination
                                count={meta_data?.total_pages ?? 0}
                                page={meta_data?.current ?? 1}
                                onChange={(event, value) => handlePageChange(value)}
                                size="large"
                                sx={{
                                    ul: {
                                        "& .Mui-selected": {
                                            background: "#6440FB",
                                            color: "white",
                                        },
                                    },
                                }}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </div>
                {/* Pagination ends here  */}
            </div>
            <LoggedInFooter />
        </div>
    );
}
