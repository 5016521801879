/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from "react";
import {COMPANY_NAME, IMAGE_FILE_URL} from "../../../../../../config/config";
import MetaComponent from "../../../../../../components/common/MetaComponent";
import Preloader from "../../../../../../components/common/Preloader";
import Header from "../../../../../../components/layout/headers/Header";
import {useDispatch, useSelector} from "react-redux";
import {
    getCustomerCourseFilterListAction,
    getCustomerCourseListAction,
    updateCourseBookmarkAction,
} from "../../../../../../redux/action/courseActions/customerCoursesActions";
import {Avatar, Box, Button, DialogTitle, Drawer, IconButton, LinearProgress, Link, Pagination} from "@mui/material";
import {Divider, Empty, Spin} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import {Close} from "@mui/icons-material";
import {
    addCourseInCartAction,
    addCourseInLocalCartAction,
    getCourseFromLocalCartAction,
    getCourseInCartAction,
} from "../../../../../../redux/action/cartActions/cartActions";
import {useSnackbar} from "notistack";
import {Link as Link2} from "react-router-dom";
import {Rating} from "@mui/material";
import Footer from "../../../../../../components/layout/footers/Footer";

const metadata = {
    title: `All Courses - ${COMPANY_NAME}`,
    description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

export default function CustomerCourses() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {categoryId, subCategoryId} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const loggedInUser = localStorage.getItem("loggedIn_user") ?? "{}";
    const parsedLoggedInData = JSON.parse(loggedInUser);

    const localStorageCart = localStorage.getItem("beauty_cart_items") ?? "[]";
    const localStorageCartItems = JSON.parse(localStorageCart);

    const [categoryOpen, setCategoryOpen] = useState(true);
    const [subCategoryOpen, setSubCategoryOpen] = useState(true);
    const [ratingOpen, setRatingOpen] = useState(false);
    const [instructorOpen, setInstructorOpen] = useState(false);
    // const [priceOpen, setPriceOpen] = useState(false);
    const [levelOpen, setLevelOpen] = useState(false);
    const [openLanguage, setOpenLanguage] = useState(false);
    const [durationOpen, setDurationOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);

    const [filterCategory, setFilterCategory] = useState(["0"]);
    const [filterSubCategory, setFilterSubCategory] = useState(["0"]);
    const [filterRating, setFilterRating] = useState(0);
    const [filterInstructor, setFilterInstructor] = useState(["0"]);
    // const [filterPrice, setFilterPrice] = useState(['0']);
    const [filterLevel, setFilterLevel] = useState(["0"]);
    const [filterLanguage, setFilterLanguage] = useState(["0"]);
    const [filterDuration, setFilterDuration] = useState(["0"]);
    const [filterFreeCourse, setFilterFreeCourse] = useState(false);
    const [subCategoryList, setSubCategoryList] = useState([]);

    const [pageHeading, setPageHeading] = useState("Courses");
    const [pageSubHeading, setPageSubHeading] = useState("Explore these courses according to your needs.");

    const subCategoryFilterRef = useRef(null);
    const categoryFilterRef = useRef(null);
    const instructorFilterRef = useRef(null);
    const imageRefs = useRef([]);

    const ImageMaxHeight = "330px";

    const filterHideShowScreenSize = 550;
    const maxFilterHeight = "350px";
    const maxFilterHeightCompare = 350;
    const rightFilterPadding = "2px";

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // useEffect hook to update the screen width when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (parsedLoggedInData.id) {
            dispatch(getCourseInCartAction());
        } else {
            dispatch(getCourseFromLocalCartAction(localStorageCartItems?.length ? localStorageCartItems : []));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, parsedLoggedInData.id]);

    const {data: courses, isLoading, meta_data} = useSelector((state) => state.courses.customerCourses);

    const {
        data: {
            category: Categories,
            rating: ratingListWithAll,
            seller: InstructorList,
            level: levels,
            audio_language: audioLanguageArray,
            duration: durations,
        },
        isLoading: isFiltersLoading,
    } = useSelector((state) => state.courses.customerCoursesFilters);

    const {data: courseCart, isLoading: isCartLoading} = useSelector((state) => state.cart.courseCart);

    const getHeader = (categoryId, subCategoryId) => {
        let tempPageHeading = "Courses";
        let tempPageSubHeading = "Explore these courses according to your needs.";

        if (categoryId) {
            let CategoryDetail = Categories?.length
                ? Categories.find((item) => String(item.value) === String(categoryId))
                : {};
            if (subCategoryId) {
                let SubCategoryDetail =
                    CategoryDetail?.subcategory?.find((item) => String(item.value) === String(subCategoryId)) ?? {};
                tempPageHeading = SubCategoryDetail?.label ?? "";
                setPageHeading(tempPageHeading);
                tempPageSubHeading = `Explore these ${SubCategoryDetail?.label ?? ""} according to your needs.`;
                setPageSubHeading(tempPageSubHeading);
            } else {
                tempPageHeading = CategoryDetail?.label ?? "";
                setPageHeading(tempPageHeading);
                tempPageSubHeading = `Explore these ${CategoryDetail?.label ?? ""} according to your needs.`;
                setPageSubHeading(tempPageSubHeading);
            }
        } else {
            setPageHeading(tempPageHeading);
            setPageSubHeading(tempPageSubHeading);
        }
    };

    useEffect(() => {
        let body = {
            page: 1,
            category: categoryId ? categoryId : null,
            sub_category: subCategoryId ? subCategoryId : null,
        };
        dispatch(getCustomerCourseListAction(body));
        dispatch(getCustomerCourseFilterListAction());
        setFilterCategory([categoryId ?? "0"]);
        setFilterSubCategory([subCategoryId ?? "0"]);
        getHeader(categoryId, subCategoryId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId, dispatch, subCategoryId]);

    useEffect(() => {
        let tempSubCategoryList = Categories?.length
            ? Categories.find((item) => String(item.value) === String(categoryId))
            : [];
        setSubCategoryList(tempSubCategoryList ? tempSubCategoryList.subcategory : ["0"]);
    }, [Categories, categoryId]);

    const findObjectsInAWithValuesFromB = (arrA, arrB) => {
        let result = [];
        arrA?.forEach((objA) => {
            if (arrB.includes(objA.value.toString())) {
                result.push(objA);
            }
        });
        return result;
    };

    const combineSubcategories = (categoryObject) => {
        let combinedSubcategories = [];
        categoryObject.forEach((obj) => {
            if (obj.subcategory) {
                combinedSubcategories = combinedSubcategories.concat(obj.subcategory);
            }
        });
        return combinedSubcategories;
    };

    const valuesPresentInA = (a, b) => {
        let result = [];
        if (b[0] === "0") {
            return ["0"];
        } else {
            b?.forEach((element) => {
                let found = a.some((obj) => obj.value.toString() === element);
                if (found) {
                    result.push(element);
                }
            });
            return result;
        }
    };

    useEffect(() => {
        if (Categories?.length) {
            if (filterCategory[0] === "0") {
                setSubCategoryList([]);
            } else {
                let selectedCategories = findObjectsInAWithValuesFromB(Categories, filterCategory);
                let selectedSubCategories = combineSubcategories(selectedCategories);
                setSubCategoryList(selectedSubCategories);
                let remainingSubCategory = valuesPresentInA(selectedSubCategories, filterSubCategory);
                setFilterSubCategory(remainingSubCategory.length ? remainingSubCategory : "0");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Categories, filterCategory]);

    useEffect(() => {
        if (subCategoryFilterRef.current) {
            const contentHeight = subCategoryFilterRef.current.scrollHeight;
            if (contentHeight > maxFilterHeightCompare) {
                subCategoryFilterRef.current.style.overflowY = "auto";
            } else {
                subCategoryFilterRef.current.style.overflowY = "hidden";
            }
        }
    }, [subCategoryOpen, subCategoryList?.length]);

    useEffect(() => {
        if (categoryFilterRef.current) {
            const contentHeight = categoryFilterRef.current.scrollHeight;
            if (contentHeight > maxFilterHeightCompare) {
                categoryFilterRef.current.style.overflowY = "auto";
            } else {
                categoryFilterRef.current.style.overflowY = "hidden";
            }
        }
    }, [categoryOpen, Categories?.length]);

    useEffect(() => {
        if (instructorFilterRef.current) {
            const contentHeight = instructorFilterRef.current.scrollHeight;
            if (contentHeight > maxFilterHeightCompare) {
                instructorFilterRef.current.style.overflowY = "auto";
            } else {
                instructorFilterRef.current.style.overflowY = "hidden";
            }
        }
    }, [instructorOpen, InstructorList?.length]);

    useEffect(() => {
        function adjustImageHeight() {
            imageRefs?.current?.forEach((ref) => {
                const image = ref;
                if (image) {
                    const width = image.offsetWidth;
                    const height = (width / 451) * 316;
                    image.style.height = `${height}px`;
                }
            });
        }
        if (courses.length > 0) {
            adjustImageHeight();
            const resizeListener = () => adjustImageHeight();
            window.addEventListener("resize", resizeListener);
            return () => {
                window.removeEventListener("resize", resizeListener);
            };
        }
    }, [courses]);

    const getRatingStars = (count) => {
        return <Rating size="small" readOnly precision={0.5} defaultValue={count} />;
    };

    const handlePageChange = (value) => {
        let body = {
            page: value,
        };
        dispatch(getCustomerCourseListAction(body));
    };

    const handleCourseClicked = (course) => {
        navigate(`/courses/${course.slug_name}`);
    };

    const handleFilterCategories = (item) => {
        if (item !== "0") {
            if (filterCategory.includes(item)) {
                const filtered = filterCategory.filter((elm) => String(elm) !== String(item));
                setFilterCategory([...filtered]);
            } else {
                setFilterCategory((pre) => [...pre, item].filter((elm) => String(elm) !== "0"));
            }
        } else {
            setFilterCategory(["0"]);
            setFilterSubCategory(["0"]);
        }
    };

    const handleFilterSubCategories = (item) => {
        if (item !== "0") {
            if (filterSubCategory.includes(item)) {
                const filtered = filterSubCategory.filter((elm) => String(elm) !== String(item));
                setFilterSubCategory([...filtered]);
            } else {
                setFilterSubCategory((pre) => [...pre, item].filter((elm) => String(elm) !== "0"));
            }
        } else {
            setFilterSubCategory(["0"]);
        }
    };

    const handleFilterInstructors = (item) => {
        if (item !== "0") {
            if (filterInstructor.includes(item)) {
                const filtered = filterInstructor.filter((elm) => String(elm) !== String(item));
                setFilterInstructor([...filtered]);
            } else {
                setFilterInstructor((pre) => [...pre, item].filter((elm) => String(elm) !== "0"));
            }
        } else {
            setFilterInstructor(["0"]);
        }
    };

    const handleFilterLevels = (item) => {
        if (item !== "0") {
            if (filterLevel.includes(item)) {
                const filtered = filterLevel.filter((elm) => String(elm) !== String(item));
                setFilterLevel([...filtered]);
            } else {
                setFilterLevel((pre) => [...pre, item].filter((elm) => String(elm) !== "0"));
            }
        } else {
            setFilterLevel(["0"]);
        }
    };

    const handleFilterLanguages = (item) => {
        if (item !== "0") {
            if (filterLanguage.includes(item)) {
                const filtered = filterLanguage.filter((elm) => String(elm) !== String(item));
                setFilterLanguage([...filtered]);
            } else {
                setFilterLanguage((pre) => [...pre, item].filter((elm) => String(elm) !== "0"));
            }
        } else {
            setFilterLanguage(["0"]);
        }
    };

    const handleFilterDuration = (item) => {
        if (item !== "0") {
            if (filterDuration.includes(item)) {
                const filtered = filterDuration.filter((elm) => String(elm) !== String(item));
                setFilterDuration([...filtered]);
            } else {
                setFilterDuration((pre) => [...pre, item].filter((elm) => String(elm) !== "0"));
            }
        } else {
            setFilterDuration(["0"]);
        }
    };

    const handleApplyFilters = () => {
        let body = {
            page: meta_data?.current ?? 1,
            category: Array.isArray(filterCategory) ? filterCategory.join(",") : filterCategory,
            subcategory: Array.isArray(filterSubCategory) ? filterSubCategory.join(",") : filterSubCategory,
            rating: Array.isArray(filterRating) ? filterRating.join(",") : filterRating,
            seller: Array.isArray(filterInstructor) ? filterInstructor.join(",") : filterInstructor,
            level: Array.isArray(filterLevel) ? filterLevel.join(",") : filterLevel,
            audio_language: Array.isArray(filterLanguage) ? filterLanguage.join(",") : filterLanguage,
            duration: Array.isArray(filterDuration) ? filterDuration.join(",") : filterDuration,
            is_course_free: filterFreeCourse,
        };
        console.log(body);
        dispatch(getCustomerCourseListAction(body));
        getHeader(0, 0);
    };

    const handleAddToCart = (course) => {
        let body = {
            course: [String(course.id)],
        };

        if (parsedLoggedInData.id) {
            dispatch(
                addCourseInCartAction(body, {
                    onLoading: (loading) => {
                        enqueueSnackbar("Loading...", {variant: "info"});
                    },
                    onError: (error) => {
                        let errorMessage = String(error?.message);
                        enqueueSnackbar(errorMessage, {variant: "error"});
                    },
                    onSuccess: (success) => {
                        enqueueSnackbar(success.message[0], {variant: "success"});
                        dispatch(getCourseInCartAction());
                    },
                })
            );
        } else {
            dispatch(addCourseInLocalCartAction(course));
        }
        const updatedCartItems = [...localStorageCartItems, course];
        localStorage.setItem("beauty_cart_items", JSON.stringify(updatedCartItems));
    };

    const isCourseAddedToCart = (courseId) => {
        if (courseCart.length) {
            return courseCart.some((item) => String(item.id) === String(courseId));
        } else {
            return false;
        }
    };

    const sideFilterArea = (
        <div className="sidebar -courses">
            {/* Category Filter Starts */}
            <div className="sidebar__item">
                <div className="accordion js-accordion">
                    <div className={`accordion__item js-accordion-item-active ${categoryOpen ? "is-active" : ""} `}>
                        <div className="accordion__button items-center" onClick={() => setCategoryOpen((pre) => !pre)}>
                            <h5 className="sidebar__title">Category</h5>

                            <div className="accordion__icon">
                                <div className="icon icon-chevron-down"></div>
                                <div className="icon icon-chevron-up"></div>
                            </div>
                        </div>

                        <div
                            className="accordion__content"
                            ref={categoryFilterRef}
                            style={categoryOpen ? {maxHeight: maxFilterHeight, paddingRight: rightFilterPadding} : {}}
                        >
                            <div className="accordion__content__inner">
                                <div className="sidebar-checkbox">
                                    {Categories?.length ? (
                                        Categories.map((elm, i) => (
                                            <div
                                                key={i}
                                                onClick={() => handleFilterCategories(String(elm.value))}
                                                className="sidebar-checkbox__item cursor"
                                            >
                                                <div className="form-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        readOnly
                                                        checked={
                                                            filterCategory.includes(String(elm.value)) ? true : false
                                                        }
                                                    />
                                                    <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>

                                                <div className="sidebar-checkbox__title">{elm.label}</div>
                                                <div className="sidebar-checkbox__count">({elm?.count ?? 0})</div>
                                            </div>
                                        ))
                                    ) : (
                                        <Empty description="No Data Found" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Category Filter Ends */}

            {/* Sub Category Filter Starts */}
            <div className="sidebar__item">
                <div className="accordion js-accordion">
                    <div className={`accordion__item js-accordion-item-active ${subCategoryOpen ? "is-active" : ""} `}>
                        <div
                            className="accordion__button items-center"
                            onClick={() => setSubCategoryOpen((pre) => !pre)}
                        >
                            <h5 className="sidebar__title">Sub Category</h5>

                            <div className="accordion__icon">
                                <div className="icon icon-chevron-down"></div>
                                <div className="icon icon-chevron-up"></div>
                            </div>
                        </div>

                        <div
                            className="accordion__content"
                            ref={subCategoryFilterRef}
                            style={
                                subCategoryOpen ? {maxHeight: maxFilterHeight, paddingRight: rightFilterPadding} : {}
                            }
                        >
                            <div className="accordion__content__inner">
                                <div className="sidebar-checkbox">
                                    <div
                                        className="sidebar-checkbox__item"
                                        onClick={() => handleFilterSubCategories("0")}
                                    >
                                        <div className="form-checkbox">
                                            <input type="checkbox" readOnly checked={filterSubCategory[0] === "0"} />
                                            <div className="form-checkbox__mark">
                                                <div className="form-checkbox__icon icon-check"></div>
                                            </div>
                                        </div>

                                        <div className="sidebar-checkbox__title">All</div>
                                        <div className="sidebar-checkbox__count"></div>
                                    </div>
                                    {subCategoryList?.length
                                        ? subCategoryList.map((elm, i) => (
                                              <div
                                                  key={i}
                                                  className="sidebar-checkbox__item cursor"
                                                  onClick={() => handleFilterSubCategories(String(elm.value))}
                                              >
                                                  <div className="form-checkbox">
                                                      <input
                                                          type="checkbox"
                                                          readOnly
                                                          checked={
                                                              filterSubCategory.includes(String(elm.value))
                                                                  ? true
                                                                  : false
                                                          }
                                                      />
                                                      <div className="form-checkbox__mark">
                                                          <div className="form-checkbox__icon icon-check"></div>
                                                      </div>
                                                  </div>

                                                  <div className="sidebar-checkbox__title">{elm.label}</div>
                                                  <div className="sidebar-checkbox__count">({elm?.count ?? 0})</div>
                                              </div>
                                          ))
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sub Category Filter Ends */}

            {/* Rating Filter Starts */}
            <div className="sidebar__item">
                <div className="accordion js-accordion">
                    <div className={`accordion__item js-accordion-item-active ${ratingOpen ? "is-active" : ""} `}>
                        <div className="accordion__button items-center" onClick={() => setRatingOpen((pre) => !pre)}>
                            <h5 className="sidebar__title">Ratings</h5>

                            <div className="accordion__icon">
                                <div className="icon icon-chevron-down"></div>
                                <div className="icon icon-chevron-up"></div>
                            </div>
                        </div>

                        <div
                            className="accordion__content"
                            style={ratingOpen ? {maxHeight: maxFilterHeight, paddingRight: rightFilterPadding} : {}}
                        >
                            <div className="accordion__content__inner">
                                <div className="sidebar-checkbox">
                                    {ratingListWithAll?.length ? (
                                        ratingListWithAll.map((elm, i) => (
                                            <div
                                                key={i}
                                                onClick={() => setFilterRating(elm.value)}
                                                className="sidebar-checkbox__item cursor"
                                            >
                                                <div className="form-radio mr-10">
                                                    <div className="radio">
                                                        <input
                                                            type="radio"
                                                            readOnly
                                                            checked={String(elm.value) === String(filterRating)}
                                                        />
                                                        <div className="radio__mark">
                                                            <div className="radio__icon"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sidebar-checkbox__title d-flex items-center">
                                                    <div className="d-flex x-gap-5 pr-10">{getRatingStars(5)}</div>
                                                    {elm.label}
                                                </div>
                                                <div className="sidebar-checkbox__count">({elm.count})</div>
                                            </div>
                                        ))
                                    ) : (
                                        <Empty />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Rating Filter Ends */}

            {/* Instructor Filter Starts */}
            <div className="sidebar__item">
                <div className="accordion js-accordion">
                    <div className={`accordion__item js-accordion-item-active ${instructorOpen ? "is-active" : ""} `}>
                        <div
                            className="accordion__button items-center"
                            onClick={() => setInstructorOpen((pre) => !pre)}
                        >
                            <h5 className="sidebar__title">Educators</h5>

                            <div className="accordion__icon">
                                <div className="icon icon-chevron-down"></div>
                                <div className="icon icon-chevron-up"></div>
                            </div>
                        </div>

                        <div
                            className="accordion__content"
                            ref={instructorFilterRef}
                            style={instructorOpen ? {maxHeight: maxFilterHeight, paddingRight: rightFilterPadding} : {}}
                        >
                            <div className="accordion__content__inner">
                                <div className="sidebar-checkbox">
                                    {InstructorList?.length ? (
                                        InstructorList.map((elm, i) => (
                                            <div
                                                key={i}
                                                onClick={() => handleFilterInstructors(String(elm.value))}
                                                className="sidebar-checkbox__item cursor"
                                            >
                                                <div className="form-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        readOnly
                                                        checked={
                                                            filterInstructor.includes(String(elm.value)) ? true : false
                                                        }
                                                    />
                                                    <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>

                                                <div className="sidebar-checkbox__title">{elm.label}</div>
                                                <div className="sidebar-checkbox__count">({elm?.count ?? 0})</div>
                                            </div>
                                        ))
                                    ) : (
                                        <Empty description="No Data Found" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Instructor Filter Ends */}

            {/* Price Filter Starts */}
            {/* <div className="sidebar__item">
				<div className="accordion js-accordion">
					<div
						className={`accordion__item js-accordion-item-active ${
							priceOpen ? "is-active" : ""
						} `}>
						<div
							className="accordion__button items-center"
							onClick={() => setPriceOpen((pre) => !pre)}>
							<h5 className="sidebar__title">Price</h5>

							<div className="accordion__icon">
								<div className="icon icon-chevron-down"></div>
								<div className="icon icon-chevron-up"></div>
							</div>
						</div>

						<div
							className="accordion__content"
							style={priceOpen ? { maxHeight: "350px" } : {}}>
							<div className="accordion__content__inner">
								<div className="sidebar-checkbox">
									{prices.map((elm, i) => (
										<div
											key={i}
											className="sidebar-checkbox__item cursor"
											onClick={() => setFilterPrice(elm.value)}>
											<div className="form-radio mr-10">
												<div className="radio">
													<input
														type="radio"
														readOnly
														checked={String(filterPrice) === String(elm.value)}
													/>
													<div className="radio__mark">
														<div className="radio__icon"></div>
													</div>
												</div>
											</div>
											<div className="sidebar-checkbox__title">{elm.label}</div>
											<div className="sidebar-checkbox__count">(0)</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
            {/* Price Filter Ends */}

            {/* Level Filter Starts */}
            <div className="sidebar__item">
                <div className="accordion js-accordion">
                    <div className={`accordion__item js-accordion-item-active ${levelOpen ? "is-active" : ""}  `}>
                        <div className="accordion__button items-center" onClick={() => setLevelOpen((pre) => !pre)}>
                            <h5 className="sidebar__title">Level</h5>

                            <div className="accordion__icon">
                                <div className="icon icon-chevron-down"></div>
                                <div className="icon icon-chevron-up"></div>
                            </div>
                        </div>
                        <div
                            className="accordion__content"
                            style={levelOpen ? {maxHeight: maxFilterHeight, paddingRight: rightFilterPadding} : {}}
                        >
                            <div className="accordion__content__inner">
                                <div className="sidebar-checkbox">
                                    {levels?.length ? (
                                        levels.map((elm, i) => (
                                            <div
                                                key={i}
                                                className="sidebar-checkbox__item cursor"
                                                onClick={() => handleFilterLevels(String(elm.value))}
                                            >
                                                <div className="form-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        readOnly
                                                        checked={filterLevel.includes(String(elm.value)) ? true : false}
                                                    />
                                                    <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>

                                                <div className="sidebar-checkbox__title">{elm.label}</div>
                                                <div className="sidebar-checkbox__count">({elm.count})</div>
                                            </div>
                                        ))
                                    ) : (
                                        <Empty />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Level Filter Ends */}

            {/* Language Filter Starts */}
            <div className="sidebar__item">
                <div className="accordion js-accordion">
                    <div className={`accordion__item js-accordion-item-active ${openLanguage ? "is-active" : ""} `}>
                        <div className="accordion__button items-center" onClick={() => setOpenLanguage((pre) => !pre)}>
                            <h5 className="sidebar__title">Languange</h5>

                            <div className="accordion__icon">
                                <div className="icon icon-chevron-down"></div>
                                <div className="icon icon-chevron-up"></div>
                            </div>
                        </div>
                        <div
                            className="accordion__content"
                            style={openLanguage ? {maxHeight: maxFilterHeight, paddingRight: rightFilterPadding} : {}}
                        >
                            <div className="accordion__content__inner">
                                <div className="sidebar-checkbox">
                                    {audioLanguageArray?.length ? (
                                        audioLanguageArray.map((elm, i) => (
                                            <div
                                                key={i}
                                                className="sidebar-checkbox__item cursor"
                                                onClick={() => handleFilterLanguages(String(elm.value))}
                                            >
                                                <div className="form-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        readOnly
                                                        checked={
                                                            filterLanguage.includes(String(elm.value)) ? true : false
                                                        }
                                                    />
                                                    <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>
                                                <div className="sidebar-checkbox__title">{elm.label}</div>
                                                <div className="sidebar-checkbox__count">({elm.count})</div>
                                            </div>
                                        ))
                                    ) : (
                                        <Empty />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Language Filter Ends */}

            {/* Duration Filter Starts */}
            <div className="sidebar__item">
                <div className="accordion js-accordion">
                    <div className={`accordion__item js-accordion-item-active ${durationOpen ? "is-active" : ""} `}>
                        <div className="accordion__button items-center" onClick={() => setDurationOpen((pre) => !pre)}>
                            <h5 className="sidebar__title">Duration</h5>

                            <div className="accordion__icon">
                                <div className="icon icon-chevron-down"></div>
                                <div className="icon icon-chevron-up"></div>
                            </div>
                        </div>

                        <div
                            className="accordion__content"
                            style={durationOpen ? {maxHeight: maxFilterHeight, paddingRight: rightFilterPadding} : {}}
                        >
                            <div className="accordion__content__inner">
                                <div className="sidebar-checkbox">
                                    {durations?.length ? (
                                        durations.map((elm, i) => (
                                            <div
                                                key={i}
                                                className="sidebar-checkbox__item cursor"
                                                onClick={() => handleFilterDuration(String(elm.value))}
                                            >
                                                <div className="form-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        readOnly
                                                        checked={
                                                            filterDuration.includes(String(elm.value)) ? true : false
                                                        }
                                                    />
                                                    <div className="form-checkbox__mark">
                                                        <div className="form-checkbox__icon icon-check"></div>
                                                    </div>
                                                </div>
                                                <div className="sidebar-checkbox__title">{elm.label}</div>
                                                <div className="sidebar-checkbox__count">({elm.count})</div>
                                            </div>
                                        ))
                                    ) : (
                                        <Empty />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Duration Filter Ends */}

            {/* Free course Filter Starts */}
            <div className="sidebar__item">
                <div className="accordion js-accordion">
                    <div className={`accordion__item js-accordion-item-active  `}>
                        <div className="accordion__button items-center">
                            <h5 className="sidebar__title">Free Courses</h5>

                            <div className="form-checkbox">
                                <input
                                    type="checkbox"
                                    readOnly
                                    checked={filterFreeCourse}
                                    onChange={(e) => setFilterFreeCourse(e.target.checked)}
                                />
                                <div className="form-checkbox__mark">
                                    <div className="form-checkbox__icon icon-check"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Free course Filter Ends */}
        </div>
    );

    const updateCourseBookmark = (item) => {
        let body = {
            course: item?.id ?? 0,
        };
        dispatch(
            updateCourseBookmarkAction(body, {
                onLoading: (loading) => {
                    enqueueSnackbar("Loading...", {variant: "info"});
                },
                onError: (error) => {
                    let errorMessage = String(error?.message);
                    enqueueSnackbar(errorMessage, {variant: "error"});
                },
                onSuccess: (success) => {
                    enqueueSnackbar(success.message[0], {variant: "success"});
                    let body = {
                        page: meta_data?.current ?? 1,
                        category: Array.isArray(filterCategory) ? filterCategory.join(",") : filterCategory,
                        subcategory: Array.isArray(filterSubCategory) ? filterSubCategory.join(",") : filterSubCategory,
                        rating: Array.isArray(filterRating) ? filterRating.join(",") : filterRating,
                        seller: Array.isArray(filterInstructor) ? filterInstructor.join(",") : filterInstructor,
                        level: Array.isArray(filterLevel) ? filterLevel.join(",") : filterLevel,
                        audio_language: Array.isArray(filterLanguage) ? filterLanguage.join(",") : filterLanguage,
                        duration: Array.isArray(filterDuration) ? filterDuration.join(",") : filterDuration,
                        is_course_free: filterFreeCourse,
                    };
                    dispatch(getCustomerCourseListAction(body));
                },
            })
        );
    };

    return (
        <div className="main-content ">
            <MetaComponent meta={metadata} />
            <Preloader />
            <Header />
            <div className="content-wrapper  js-content-wrapper overflow-hidden">
                {/* Page header starts */}
                <section className="page-header -type-1 mt-90">
                    <div className="container">
                        <div className="page-header__content">
                            <div className="row">
                                <div className="col-auto">
                                    <div>
                                        <h1 className="page-header__title">{pageHeading}</h1>
                                    </div>

                                    <div>
                                        <p className="page-header__text">{pageSubHeading}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Page header ends */}

                {screenWidth < filterHideShowScreenSize && (
                    <Drawer
                        anchor="right"
                        open={filterOpen}
                        onClose={() => setFilterOpen(false)}
                        sx={{
                            "& .MuiDrawer-paper": {
                                width: "90%",
                                maxWidth: "90vw",
                            },
                        }}
                        SlideProps={{
                            translate: "yes",
                            timeout: 1000,
                        }}
                    >
                        <Box>
                            <DialogTitle
                                sx={{
                                    m: 0,
                                    p: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                                id="customized-dialog-title"
                            >
                                Course Detail
                                <Link className="h-40 px-25 text-purple-1 mr-15" onClick={() => handleApplyFilters()}>
                                    {isLoading ? "Loading..." : "Apply Filters"}
                                </Link>
                            </DialogTitle>

                            <IconButton
                                aria-label="close"
                                onClick={() => setFilterOpen(false)}
                                sx={{
                                    position: "absolute",
                                    right: 8,
                                    top: 10,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <Close />
                            </IconButton>
                            {isLoading && <LinearProgress />}
                            <Divider />
                            <div className="pl-20 pr-20">{!isLoading && sideFilterArea}</div>
                        </Box>
                    </Drawer>
                )}

                <section className="layout-pt layout-pb-lg">
                    <div className="container">
                        <div className="row y-gap-50">
                            {screenWidth > filterHideShowScreenSize && (
                                <div className="col-xl-3 col-lg-4">
                                    <div className="pr-30 lg:pr-0">
                                        <Spin spinning={isFiltersLoading}>{sideFilterArea}</Spin>
                                    </div>
                                </div>
                            )}

                            <div className="col-xl-9 col-lg-8">
                                <div className="accordion js-accordion">
                                    <div className={`accordion__item ${filterOpen ? "is-active" : ""} `}>
                                        <div className="row y-gap-20 items-center justify-between pb-30">
                                            <div className="col-auto">
                                                <div className="text-14 lh-12">
                                                    Showing{" "}
                                                    <span className="text-dark-1 fw-500">
                                                        {courses?.length ? courses.length : 0}
                                                    </span>{" "}
                                                    total results
                                                </div>
                                            </div>
                                            {screenWidth > filterHideShowScreenSize && (
                                                <div className="col-auto">
                                                    <Button
                                                        component="label"
                                                        className="button h-40 px-25 -dark-1 -dark-button-white text-white"
                                                        onClick={() => handleApplyFilters()}
                                                    >
                                                        {isLoading ? "Loading..." : "Apply Filters"}
                                                    </Button>
                                                </div>
                                            )}
                                            {screenWidth < filterHideShowScreenSize && (
                                                <div className="col-auto sm:d-block md:d-none lg:d-none xl:d-none xxl:d-none">
                                                    <button
                                                        className="button h-50 px-30 -light-7 text-purple-1"
                                                        onClick={() => setFilterOpen((pre) => !pre)}
                                                    >
                                                        <i className="icon-filter mr-10"></i>
                                                        Filter
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Data Part starts from here  */}
                                <Spin spinning={isLoading}>
                                    <div className="row y-gap-30 side-content__wrap">
                                        {courses.length ? (
                                            courses.map((elm, i) => (
                                                <div
                                                    key={i}
                                                    className="side-content col-xl-4 col-lg-6 col-md-4 col-sm-6"
                                                >
                                                    <div className="coursesCard -type-1">
                                                        <div className="relative">
                                                            <div className="coursesCard__image overflow-hidden rounded-8">
                                                                <Avatar
                                                                    src={elm?.course_thumbnail_image ?? ""}
                                                                    alt="courses"
                                                                    variant="square"
                                                                    sx={{
                                                                        maxHeight: ImageMaxHeight,
                                                                        width: "100%",
                                                                    }}
                                                                    ref={(el) => (imageRefs.current[i] = el)}
                                                                />
                                                                <div className="coursesCard__image_overlay rounded-8"></div>
                                                            </div>
                                                            <div className="d-flex justify-between py-10 px-10 absolute-full-center z-3">
                                                                {elm.is_popular_badge && (
                                                                    <div>
                                                                        <div className="px-15 rounded-200 bg-purple-1">
                                                                            <span className="text-11 lh-1 uppercase fw-500 text-white">
                                                                                Popular
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {elm.is_best_seller_badge && (
                                                                    <div>
                                                                        <div className="px-15 rounded-200 bg-green-1">
                                                                            <span className="text-11 lh-1 uppercase fw-500 text-dark-1">
                                                                                Best sellers
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="h-100 pt-15">
                                                            <div className="d-flex items-center">
                                                                <div className="text-14 lh-1 text-yellow-1 mr-10">
                                                                    {elm?.ratings_obj?.total_rating ?? 0}
                                                                </div>
                                                                <div className="d-flex x-gap-5 items-center">
                                                                    {getRatingStars(
                                                                        elm?.ratings_obj?.total_rating ?? 0
                                                                    )}
                                                                </div>
                                                                <div className="text-13 lh-1 ml-10">
                                                                    ({elm?.ratings_obj?.total_reviews_count ?? 0})
                                                                </div>
                                                            </div>

                                                            <div className="text-17 lh-15 fw-500 text-dark-1 mt-10">
                                                                <Link
                                                                    className="linkCustom"
                                                                    onClick={() => handleCourseClicked(elm)}
                                                                >
                                                                    {elm.title}
                                                                </Link>
                                                            </div>

                                                            <div
                                                                className="d-flex x-gap-10 items-center pt-10"
                                                                style={{flexWrap: "wrap"}}
                                                            >
                                                                <div className="d-flex items-center">
                                                                    <div className="mr-8">
                                                                        <img
                                                                            src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/1.svg`}
                                                                            alt="icon"
                                                                        />
                                                                    </div>
                                                                    <div className="text-14 lh-1">
                                                                        {elm?.lesson_count ?? 0} lesson
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex items-center">
                                                                    <div className="mr-8">
                                                                        <img
                                                                            src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/2.svg`}
                                                                            alt="icon"
                                                                        />
                                                                    </div>
                                                                    <div className="text-14 lh-1">
                                                                        {elm.course_duration}
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex items-center">
                                                                    <div className="mr-8">
                                                                        <img
                                                                            src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/3.svg`}
                                                                            alt="icon"
                                                                        />
                                                                    </div>
                                                                    <div className="text-14 lh-1">{elm.level}</div>
                                                                </div>
                                                            </div>

                                                            <div className="coursesCard-footer">
                                                                <div className="coursesCard-footer__author">
                                                                    <Avatar
                                                                        src={elm?.seller_obj?.user_profile_image ?? ""}
                                                                        alt="author"
                                                                        style={{
                                                                            width: "35px",
                                                                            height: "35px",
                                                                            marginRight: "5px",
                                                                            marginLeft: "0px",
                                                                        }}
                                                                    />
                                                                    <Link2
                                                                        className="linkCustom"
                                                                        to={`/instructors/${elm?.seller_obj?.slug_name}`}
                                                                    >
                                                                        {elm?.seller_obj?.user_first_name +
                                                                            " " +
                                                                            elm?.seller_obj?.user_last_name}
                                                                    </Link2>
                                                                </div>

                                                                <div className="coursesCard-footer__price">
                                                                    {!elm.is_course_free ? (
                                                                        <>
                                                                            <div>${elm.course_price}</div>
                                                                            <div>${elm.sale_price}</div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div>${elm.course_price}</div>
                                                                            <div>Free</div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="side-content__item">
                                                        <div className="px-30 pt-20 pb-30 bg-white rounded-8 border-light shadow-2">
                                                            <div
                                                                className="text-18 lh-16 text-dark-1"
                                                                style={{fontWeight: "bold"}}
                                                            >
                                                                {elm.title ? elm.title : "Title is not available."}
                                                            </div>

                                                            <div className="row x-gap-10 y-gap-10 items-center pt-15">
                                                                <div className="col-auto">
                                                                    <div className="d-flex items-center">
                                                                        <img
                                                                            className="mr-8"
                                                                            src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/1.svg`}
                                                                            alt="icon"
                                                                        />
                                                                        <div className="text-14 lh-1">
                                                                            {elm?.lesson_count ?? 0} lesson
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-auto">
                                                                    <div className="d-flex items-center">
                                                                        <img
                                                                            className="mr-8"
                                                                            src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/2.svg`}
                                                                            alt="icon"
                                                                        />
                                                                        <div className="text-14 lh-1">
                                                                            {elm.course_duration}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-auto">
                                                                    <div className="d-flex items-center">
                                                                        <img
                                                                            className="mr-8"
                                                                            src={`${IMAGE_FILE_URL}/assets/img/coursesCards/icons/3.svg`}
                                                                            alt="icon"
                                                                        />
                                                                        <div className="text-14 lh-1">{elm.level}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {elm.is_best_seller_badge && (
                                                                <div className="d-inline-block px-15 py-5 bg-green-1 text-dark-1 rounded-200 text-11 fw-500 uppercase mt-20">
                                                                    BEST SELLER
                                                                </div>
                                                            )}

                                                            {elm.short_description && (
                                                                <p className="text-dark-1 mt-15">
                                                                    {elm.short_description
                                                                        ? elm.short_description
                                                                        : "Short description is not available for this course."}
                                                                </p>
                                                            )}

                                                            <div className="row y-gap-15 pt-15">
                                                                {elm.what_student_learn
                                                                .slice(0, 4)
                                                                .map((elm_itm, i) => (
                                                                    <div key={i} className="d-flex items-center">
                                                                        <div className="d-flex justify-center items-center border-light rounded-full size-20 mr-10">
                                                                            <FontAwesomeIcon
                                                                                icon={faCheck}
                                                                                style={{
                                                                                    transform: "scale(0.7)",
                                                                                    opacity: "0.7",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <p>{elm_itm ? elm_itm : "Not available yet"}</p>
                                                                    </div>
                                                                ))}
                                                                {/* <div className="col-12">
																	<div className="d-flex items-center">
																		<div className="size-20 d-flex items-center justify-center rounded-full border-light">
																			<div className="icon-check text-6"></div>
																		</div>
																		<div className="ml-10">
																			Become a UX designer.
																		</div>
																	</div>
																</div> */}

                                                                {/* <div className="col-12">
																	<div className="d-flex items-center">
																		<div className="size-20 d-flex items-center justify-center rounded-full border-light">
																			<div className="icon-check text-6"></div>
																		</div>
																		<div className="ml-10">
																			You will be able to add UX designer.
																		</div>
																	</div>
																</div> */}

                                                                {/* <div className="col-12">
																	<div className="d-flex items-center">
																		<div className="size-20 d-flex items-center justify-center rounded-full border-light">
																			<div className="icon-check text-6"></div>
																		</div>
																		<div className="ml-10">
																			Become a UI designer.
																		</div>
																	</div>
																</div> */}

                                                                {/* <div className="col-12">
																	<div className="d-flex items-center">
																		<div className="size-20 d-flex items-center justify-center rounded-full border-light">
																			<div className="icon-check text-6"></div>
																		</div>
																		<div className="ml-10">
																			Build &amp; test a full website design.
																		</div>
																	</div>
																</div> */}
                                                            </div>

                                                            <div className="row x-gap-20 y-gap-15 items-center pt-30">
                                                                <div className="col">
                                                                    {isCourseAddedToCart(elm.id) ? (
                                                                        <button
                                                                            style={{padding: "0px 54px"}}
                                                                            className="button -md h-60 -purple-1 text-white col-12 py-54"
                                                                            disabled
                                                                        >
                                                                            Already Added
                                                                        </button>
                                                                    ) : elm?.course_purchased ? (
                                                                        <button
                                                                            style={{padding: "0px 54px"}}
                                                                            className="button -md h-60 -purple-1 text-white col-12 py-54"
                                                                            disabled
                                                                        >
                                                                            Already Purchased
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            style={{padding: "0px 54px"}}
                                                                            className="button -md h-60 -purple-1 text-white col-12 py-54"
                                                                            onClick={() => handleAddToCart(elm)}
                                                                            disabled={isCartLoading}
                                                                        >
                                                                            {isCartLoading
                                                                                ? "Loading..."
                                                                                : "Add To Cart"}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                                {parsedLoggedInData.id ? (
                                                                    <div className="col-auto">
                                                                        <div
                                                                            onClick={() => updateCourseBookmark(elm)}
                                                                            className={`d-flex items-center pointer justify-center size-60 rounded-full ${
                                                                                elm?.is_bookmarked
                                                                                    ? "border-purple"
                                                                                    : "border-light"
                                                                            }`}
                                                                        >
                                                                            {elm?.is_bookmarked ? (
                                                                                <div className="icon-bookmark-filled text-20 text-purple-1"></div>
                                                                            ) : (
                                                                                <div className="icon-bookmark text-20 text-purple-1"></div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <Empty description="Courses are not published yet" />
                                        )}
                                    </div>
                                </Spin>
                                {/* Data Part ends here */}

                                {/* Pagination Starts here */}
                                <div className="row justify-center pt-90 lg:pt-50">
                                    <div className="col-auto">
                                        <div className="pagination -buttons d-flex justify-content-center">
                                            <Pagination
                                                count={meta_data?.total_pages ?? 0}
                                                page={meta_data?.current ?? 1}
                                                onChange={(event, value) => handlePageChange(value)}
                                                size="large"
                                                sx={{
                                                    ul: {
                                                        "& .Mui-selected": {
                                                            background: "#6440FB",
                                                            color: "white",
                                                        },
                                                    },
                                                }}
                                                disabled={isLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Pagination ends here  */}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </div>
    );
}
