import React, {useEffect, useState} from "react";
import UploadCourseThumbnail from "./UploadCourseThumbnails";
import {useDispatch, useSelector} from "react-redux";
import {
    addCourseAction,
    emptyCourseDetailsAction,
    emptyCoursePreviewAction,
    getCourseDetailsAction,
    getCoursePreviewAction,
    getMediaUploadLink,
    updateCourseAction,
} from "../../../../../../redux/action/myDashboardActions/coursesActions";
import {
    COURSE_THUMBNAIL_IMAGE_AWS_STARTING_PATH,
    COURSE_THUMBNAIL_VIDEO_AWS_STARTING_PATH,
} from "../../../../../../config/config";
import {useSnackbar} from "notistack";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Skeleton,
    Typography,
} from "@mui/material";
import {getCourseCategories} from "../../../../../../redux/action/courseActions/courseCategoryActions";
import {Close, ExpandMore} from "@mui/icons-material";
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import {ContentState, EditorState, convertToRaw, convertFromHTML} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Curriculum from "./Curriculum";
import {useLocation} from "react-router-dom";
import {audioLanguageArray, courseLevelArray} from "../../../../../../objectTemplates/objectTemplates";
import CoursePreviewPage from "./CoursePreviewPage";
import {Tooltip} from "antd";
import {LoggedInFooter} from "../../../../../../components/layout/footers/LoggedInFooter";

export default function CreateCourse() {
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();
    const dispatch = useDispatch();

    const isPathIsUpdated = location.pathname.includes("update-course");

    useEffect(() => {
        if (isPathIsUpdated && location?.state?.slug_name) {
            dispatch(getCourseDetailsAction(location?.state.slug_name));
            dispatch(getCoursePreviewAction(location?.state.slug_name));
        } else {
            dispatch(emptyCourseDetailsAction());
            dispatch(emptyCoursePreviewAction());
        }
    }, [dispatch, isPathIsUpdated, location?.state?.slug_name]);

    const {data: Categories, isLoading, isSuccess} = useSelector((state) => state.courses.categories);

    const {data: CourseDetail, isLoading: isCourseDetailLoading} = useSelector(
        (state) => state.myDashboard.sellerCourseDetails
    );

    const {data: CoursePreview, isLoading: isCoursePreviewLoading} = useSelector(
        (state) => state.myDashboard.sellerCoursePreview
    );

    const [courseThumbnailImage, setCourseThumbnailImage] = useState("");
    const [courseThumbnailVideo, setCourseThumbnailVideo] = useState("");
    const [courseThumbnailImageURL, setCourseThumbnailImageURL] = useState("");
    const [courseThumbnailVideoURL, setCourseThumbnailVideoURL] = useState("");
    const [loading, setLoading] = useState(false);
    const [imageUploadLoading, setImageUploadLoading] = useState(false);
    const [videoUploadLoading, setVideoUploadLoading] = useState(false);
    const [uploadedPath, setUploadedPath] = useState({
        image: "",
        video: "",
    });
    const [categoryLoading, setCategoryLoading] = useState(isLoading);
    const [subCategories, setSubCategories] = useState([]);
    const [expanded, setExpanded] = useState("panel1");
    const [course, setCourse] = useState({
        id: 0,
    });
    const [openDetailPage, setOpenDetailPage] = useState(false);
    const [showAutoSave, setShowAutoSave] = useState(false);
    const [isCourseFree, setIsCourseFree] = useState(false);

    const isImagePresent = courseThumbnailImageURL.includes("amazonaws");
    const isVideoPresent = courseThumbnailVideoURL.includes("amazonaws");

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
        control,
    } = useForm({
        defaultValues: {
            title: "",
            short_description: "",
            description: "",
            level: "BEGINNER",
            audio_language: "1",
            course_price: "",
            sale_price: "",
            category: "",
            sub_category: "",
            course_thumbnail_image: "",
            what_student_learn: [""],
            material_included: [""],
            requirements: EditorState.createEmpty(),
            is_course_free: false,
            course_thumbnail_video: null,
            course_status: "DRAFT",
        },
    });
    const category = useWatch({control, name: "category"});
    const subCategory = useWatch({control, name: "sub_category"});
    const requirements = useWatch({control, name: "requirements"});
    const whatStudentLearn = useWatch({control, name: "what_student_learn"});
    const materialIncluded = useWatch({control, name: "material_included"});

    const [finalThumbnailStatus, setFinalThumbnailStatus] = useState({
        thumbnail: true,
    });

    useEffect(() => {
        if (isPathIsUpdated) {
            setCourse(CourseDetail);
            setValue("title", CourseDetail?.title ?? "");
            setValue("short_description", CourseDetail?.short_description ?? "");
            setValue("description", CourseDetail?.description ?? "");
            setValue("level", CourseDetail?.level ?? "BEGINNER");
            setValue("audio_language", CourseDetail?.audio_language ?? "1");
            setValue("course_price", CourseDetail?.course_price ?? "");
            setValue("sale_price", CourseDetail?.sale_price ?? "");
            setValue("category", CourseDetail?.category ?? "");
            setValue("sub_category", CourseDetail?.sub_category ?? "");
            setValue("what_student_learn", CourseDetail?.what_student_learn ?? [""]);
            setValue("material_included", CourseDetail?.material_included ?? [""]);
            setValue("is_course_free", CourseDetail?.is_course_free);
            setValue("course_status", CourseDetail?.course_status ?? "DRAFT");

            const htmlContent = CourseDetail?.requirements || "";
            // Convert the HTML content to ContentState
            const blocksFromHTML = convertFromHTML(htmlContent);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            // Create EditorState with the obtained ContentState
            const requirementsEditorState = EditorState.createWithContent(contentState);
            // Set the EditorState value
            setValue("requirements", requirementsEditorState);

            setCourseThumbnailImageURL(CourseDetail?.course_thumbnail_image ?? "");
            setCourseThumbnailVideoURL(CourseDetail?.course_thumbnail_video ?? "");

            let newSubCategories = CourseDetail?.category
                ? Categories.find((item) => String(item.id) === String(CourseDetail?.category))
                : {};
            setSubCategories(newSubCategories?.sub_category ?? []);
        } else {
            setCourse({
                id: 0,
            });
            setValue("title", "");
            setValue("short_description", "");
            setValue("description", "");
            setValue("level", "BEGINNER");
            setValue("audio_language", "1");
            setValue("course_price", "");
            setValue("sale_price", "");
            setValue("category", "");
            setValue("sub_category", "");
            setValue("what_student_learn", [""]);
            setValue("material_included", [""]);
            setValue("is_course_free", false);
            setValue("course_status", "DRAFT");
            setValue("requirements", EditorState.createEmpty());

            setCourseThumbnailImageURL("");
            setCourseThumbnailVideoURL("");
        }
    }, [Categories, CourseDetail, isPathIsUpdated, setValue]);

    const {remove, append} = useFieldArray({
        control,
        name: "what_student_learn",
    });

    const {remove: removeMaterial, append: appendMaterial} = useFieldArray({
        control,
        name: "material_included",
    });

    useEffect(() => {
        !isSuccess &&
            dispatch(
                getCourseCategories({
                    onLoading: (loading) => {
                        setCategoryLoading(true);
                    },
                    onError: (error) => {
                        setCategoryLoading(false);
                        let errorMessage = String(error?.message ?? "Something went wrong");
                        enqueueSnackbar(errorMessage, {variant: "error"});
                    },
                    onSuccess: (response) => {
                        setCategoryLoading(false);
                    },
                })
            );
    }, [dispatch, isSuccess, enqueueSnackbar]);

    const handleFreeCourse = () => {
        setIsCourseFree(()=>{
			setValue("sale_price", !isCourseFree ? 0 : "");
			return !isCourseFree
		});
		
    };

    const handleShowAutoText = () => {
        setShowAutoSave(true);
        setTimeout(() => {
            setShowAutoSave(false);
        }, 4000);
    };

    // Function to generate a random string
    function generateRandomString(length) {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    // Function to generate a unique filename
    function generateUniqueFilename() {
        const timestamp = new Date().getTime(); // Current timestamp
        const randomString = generateRandomString(8); // Generate a random string of length 8
        return `${timestamp}_${randomString}`;
    }

    const handleFinalStatus = (fileType) => {
        let temporaryStatus = finalThumbnailStatus;

        const operateFinalSteps = () => {
            // final decision
            setExpanded("panel3");
        };

        if (fileType === "IMAGE") {
            delete temporaryStatus.thumbnail;
            if (Object.keys(temporaryStatus).length === 1) {
                operateFinalSteps();
                return;
            }
            delete temporaryStatus.image;
            if (Object.keys(temporaryStatus).length === 1) {
                if (temporaryStatus?.video === false || temporaryStatus?.attachment === false) {
                    operateFinalSteps();
                    return;
                }
            }
            if (Object.keys(temporaryStatus).length === 2) {
                if (temporaryStatus?.video === false && temporaryStatus?.attachment === false) {
                    operateFinalSteps();
                    return;
                }
            }
        } else if (fileType === "VIDEO") {
            delete temporaryStatus.thumbnail;
            if (Object.keys(temporaryStatus).length === 1) {
                operateFinalSteps();
                return;
            }
            delete temporaryStatus.video;
            if (Object.keys(temporaryStatus).length === 1) {
                if (temporaryStatus?.image === false || temporaryStatus?.attachment === false) {
                    operateFinalSteps();
                    return;
                }
            }
            if (Object.keys(temporaryStatus).length === 2) {
                if (temporaryStatus?.image === false && temporaryStatus?.attachment === false) {
                    operateFinalSteps();
                    return;
                }
            }
        }
    };

    const uploadFilesOnStorage = async (url, file, fileName, type) => {
        if (file) {
            type === "IMAGE" ? setImageUploadLoading(true) : setVideoUploadLoading(true);

            const renamedFile = new File([file], fileName, {type: file.type});

            try {
                await fetch(url, {
                    method: "PUT",
                    body: renamedFile,
                });
                let body = {};

                if (type === "IMAGE") {
                    body.course_thumbnail_image = `${COURSE_THUMBNAIL_IMAGE_AWS_STARTING_PATH}${fileName}`;
                } else {
                    body.course_thumbnail_video = `${COURSE_THUMBNAIL_VIDEO_AWS_STARTING_PATH}${fileName}`;
                }

                dispatch(
                    updateCourseAction(body, course.id, {
                        onLoading: (loading) => {
                            setLoading(true);
                        },
                        onError: (error) => {
                            setLoading(false);
                            let errorMessage = String(error?.message ?? "Something went wrong");
                            enqueueSnackbar(errorMessage, {variant: "error"});
                            type === "IMAGE" ? setImageUploadLoading(false) : setVideoUploadLoading(false);
                        },
                        onSuccess: (success) => {
                            setLoading(false);
                            setCourse(success.data);
                            success?.data?.slug_name && dispatch(getCoursePreviewAction(success?.data?.slug_name));
                            if (type === "IMAGE") {
                                setValue("course_thumbnail_image", body.course_thumbnail_image);
                                enqueueSnackbar("Image Added Successfully", {
                                    variant: "success",
                                });
                                setFinalThumbnailStatus((prevState) => ({
                                    ...prevState,
                                    image: false,
                                }));
                                setImageUploadLoading(false);
                                handleFinalStatus("IMAGE");
                            } else {
                                setValue("course_thumbnail_video", body.course_thumbnail_video);
                                enqueueSnackbar("Video Added Successfully", {
                                    variant: "success",
                                });
                                setFinalThumbnailStatus((prevState) => ({
                                    ...prevState,
                                    image: false,
                                }));
                                setVideoUploadLoading(false);
                                handleFinalStatus("VIDEO");
                            }
                        },
                    })
                );
            } catch (error) {
                enqueueSnackbar(error, {variant: "error"});
                type === "IMAGE" ? setImageUploadLoading(false) : setVideoUploadLoading(false);
            }
        }
    };

    const handleImageUpload = () => {
        setImageUploadLoading(true);
        const ImageExtension = courseThumbnailImage.name.split(".").pop();
        const imageName = generateUniqueFilename() + "." + ImageExtension;

        dispatch(
            getMediaUploadLink(`${COURSE_THUMBNAIL_IMAGE_AWS_STARTING_PATH}${imageName}`, {
                onLoading: (loading) => {
                    setImageUploadLoading(true);
                },
                onError: (error) => {
                    setImageUploadLoading(false);
                    let errorMessage = String(error?.message ?? "Something went wrong");
                    enqueueSnackbar(errorMessage, {variant: "error"});
                },
                onSuccess: (success) => {
                    uploadFilesOnStorage(success?.data?.signed_url, courseThumbnailImage, imageName, "IMAGE");
                    setUploadedPath((prevState) => ({
                        ...prevState,
                        image: success?.data?.signed_url || prevState.image,
                    }));
                },
            })
        );
    };

    const handleVideoUpload = () => {
        setVideoUploadLoading(true);
        const VideoExtension = courseThumbnailVideo.name.split(".").pop();
        const videoName = generateUniqueFilename() + "." + VideoExtension;
        dispatch(
            getMediaUploadLink(`${COURSE_THUMBNAIL_VIDEO_AWS_STARTING_PATH}${videoName}`, {
                onLoading: (loading) => {
                    setVideoUploadLoading(true);
                },
                onError: (error) => {
                    setVideoUploadLoading(false);
                    let errorMessage = String(error?.message ?? "Something went wrong");
                    enqueueSnackbar(errorMessage, {variant: "error"});
                },
                onSuccess: (success) => {
                    setVideoUploadLoading(false);
                    uploadFilesOnStorage(success?.data?.signed_url, courseThumbnailVideo, videoName, "VIDEO");
                    setUploadedPath((prevState) => ({
                        ...prevState,
                        video: success?.data?.signed_url || prevState.video,
                    }));
                },
            })
        );
    };

    const handleUploadFiles = () => {
        if (!isImagePresent && courseThumbnailImageURL) {
            handleImageUpload();
        }
        if (!isVideoPresent && courseThumbnailVideoURL) {
            handleVideoUpload();
        }
    };

    const onSubmitBasicDetails = async (data) => {
        setLoading(true);
        // to submit basic details , first convert requirements key through this
        const requirementsHtml = draftToHtml(convertToRaw(requirements.getCurrentContent()));
        const body = {
            ...data,
            requirements: requirementsHtml,
            short_description: data.short_description ? data.short_description : null,
            description: data.description ? data.description : null,
            what_student_learn: data.what_student_learn.length ? data.what_student_learn : null,
            material_included: data.material_included.length ? data.material_included : null,
            level: data.level ? data.level : null,
            audio_language: data.audio_language ? data.audio_language : null,
            category: data.category ? data.category : null,
            sub_category: data.sub_category ? data.sub_category : null,
            is_course_free: data.is_course_free ? data.is_course_free : false,
            course_price: data.course_price ? data.course_price : null,
            sale_price: data.is_course_free ? 0 : data.sale_price ? data.sale_price : null,
            course_thumbnail_image: data.course_thumbnail_image ? data.course_thumbnail_image : null,
            course_thumbnail_video: data.course_thumbnail_video ? data.course_thumbnail_video : null,
        };
        if (course.id) {
            delete body.course_thumbnail_image;
            delete body.course_thumbnail_video;
            dispatch(
                updateCourseAction(body, course.id, {
                    onLoading: (loading) => {
                        setLoading(true);
                    },
                    onError: (error) => {
                        setLoading(false);
                        let errorMessage = String(error?.message ?? "Something went wrong");
                        enqueueSnackbar(errorMessage, {variant: "error"});
                    },
                    onSuccess: (success) => {
                        setLoading(false);
                        setCourse(success.data);
                        success?.data?.slug_name && dispatch(getCoursePreviewAction(success?.data?.slug_name));
                        enqueueSnackbar("Course Updated Successfully...", {
                            variant: "success",
                        });
                    },
                })
            );
        } else {
            dispatch(
                addCourseAction(body, {
                    onLoading: (loading) => {
                        setLoading(true);
                    },
                    onError: (error) => {
                        setLoading(false);
                        let errorMessage = String(error?.message ?? "Something went wrong");
                        enqueueSnackbar(errorMessage, {variant: "error"});
                    },
                    onSuccess: (success) => {
                        setLoading(false);
                        setCourse(success.data);
                        success?.data?.slug_name && dispatch(getCoursePreviewAction(success?.data?.slug_name));
                        enqueueSnackbar("Course Added Successfully...", {
                            variant: "success",
                        });
                        setExpanded("panel2");
                    },
                })
            );
        }
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleCourseClicked = () => {
        setOpenDetailPage(true);
    };

    let readyToPublished =
        CoursePreview.is_available_for_published &&
        (CoursePreview.course_status === "DRAFT" || CoursePreview.course_status === "UN_PUBLISHED");

    let courseAlreadyPublished = CoursePreview.course_status === "PUBLISHED";

    const handleCourseLive = (status) => {
        let body = {
            course_status: status,
        };
        setLoading(true);
        dispatch(
            updateCourseAction(body, CoursePreview.id, {
                onLoading: (loading) => {
                    setLoading(true);
                    enqueueSnackbar("Loading. Please wait...", {variant: "info"});
                },
                onError: (error) => {
                    setLoading(false);
                    let errorMessage = String(error?.message ?? "Something went wrong");
                    enqueueSnackbar(errorMessage, {variant: "error"});
                },
                onSuccess: (success) => {
                    setLoading(false);
                    enqueueSnackbar("Course Updated Successfully...", {
                        variant: "success",
                    });
                    CoursePreview.slug_name && dispatch(getCoursePreviewAction(CoursePreview.slug_name));
                },
            })
        );
    };

    const handleImageDelete = () => {
        setLoading(true);
        setCourseThumbnailImage("");
        setCourseThumbnailImageURL("");
        document.getElementById("imageUpload1").value = "";
        let body = {
            course_thumbnail_image: null,
        };
        dispatch(
            updateCourseAction(body, course.id, {
                onLoading: (loading) => {
                    setLoading(true);
                },
                onError: (error) => {
                    setLoading(false);
                    let errorMessage = String(error?.message ?? "Something went wrong");
                    enqueueSnackbar(errorMessage, {variant: "error"});
                },
                onSuccess: (success) => {
                    setLoading(false);
                    setCourse(success.data);
                    success?.data?.slug_name && dispatch(getCoursePreviewAction(success?.data?.slug_name));
                    enqueueSnackbar("Image Removed Successfully...", {
                        variant: "success",
                    });
                },
            })
        );
    };

    const handleVideoDelete = () => {
        setLoading(true);
        setCourseThumbnailVideo("");
        setCourseThumbnailVideoURL("");
        document.getElementById("videoUpload1").value = "";
        let body = {
            course_thumbnail_video: null,
        };
        dispatch(
            updateCourseAction(body, course.id, {
                onLoading: (loading) => {
                    setLoading(true);
                },
                onError: (error) => {
                    setLoading(false);
                    let errorMessage = String(error?.message ?? "Something went wrong");
                    enqueueSnackbar(errorMessage, {variant: "error"});
                },
                onSuccess: (success) => {
                    setLoading(false);
                    setCourse(success.data);
                    success?.data?.slug_name && dispatch(getCoursePreviewAction(success?.data?.slug_name));
                    enqueueSnackbar("Video Removed Successfully...", {
                        variant: "success",
                    });
                },
            })
        );
    };

    return (
        <div className="dashboard__main">
            <div className="dashboard__content bg-light-4">
                {openDetailPage && (
                    <CoursePreviewPage open={openDetailPage} setOpen={setOpenDetailPage} type={"trainer"} />
                )}
                {/* Page Heading Starts */}
                <div className="row pb-50 mb-10 mt-50 d-flex justify-between">
                    <div className="col-auto">
                        <h1 className="text-30 lh-1 fw-700">{isPathIsUpdated ? "Update Course" : "Create Course"}</h1>
                        <div className="mt-10">
                            Welcome to the {isPathIsUpdated ? "Update Course" : "Create Course"} Section.
                        </div>
                    </div>
                    <div className="col-auto d-flex sm:py-30">
                        {courseAlreadyPublished ? (
                            <button
                                type="button"
                                disabled={isCoursePreviewLoading}
                                onClick={() => handleCourseLive("UN_PUBLISHED")}
                                className={`button h-50 px-25 -dark-1 -dark-button-white text-white`}
                            >
                                {isCoursePreviewLoading ? "Loading..." : "Un-Publish"}
                            </button>
                        ) : (
                            <Tooltip title={!readyToPublished ? "All fields are Required." : ""}>
                                <button
                                    type="button"
                                    disabled={!readyToPublished || isCoursePreviewLoading}
                                    onClick={() => handleCourseLive("PUBLISHED")}
                                    className={`button h-50 px-25 ${
                                        readyToPublished ? "-dark-1 -dark-button-white text-white" : ""
                                    }`}
                                >
                                    {isCoursePreviewLoading ? "Loading..." : "Publish it"}
                                </button>
                            </Tooltip>
                        )}
                        &nbsp;
                        <button
                            type="button"
                            disabled={!CoursePreview?.id}
                            onClick={() => handleCourseClicked()}
                            className={`button h-50 px-25 ${
                                CoursePreview?.id ? "-dark-1 -dark-button-white text-white" : ""
                            }`}
                        >
                            Preview
                        </button>
                    </div>
                </div>
                {/* Page Heading ends */}
                <div className="row y-gap-30">
                    {/* Basic Information Starts */}
                    <Accordion expanded={expanded === "panel1"} onChange={handleAccordionChange("panel1")}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <div sx={{width: "50%", flexShrink: 0}}>
                                <div className="d-flex items-center px-30">
                                    <h2 className="text-17 lh-1 fw-500">Basic Information</h2>
                                </div>
                            </div>
                            {/* {course.id ? (
								<Typography sx={{ color: "green" }}>
									Working on {course.title}
								</Typography>
							) : null} */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="rounded-16 bg-white -dark-bg-dark-1 shadow-4 h-100">
                                {isCourseDetailLoading && <LinearProgress />}
                                <div className="py-30 px-30">
                                    <form
                                        onSubmit={handleSubmit(onSubmitBasicDetails)}
                                        className="contact-form row y-gap-30"
                                        action="#"
                                    >
                                        {/* Title */}
                                        <div className="col-12">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                Course Title<span className="text-red">*</span>
                                            </label>
                                            <input
                                                {...register("title", {required: true})}
                                                type="text"
                                                placeholder="Learn Figma - UI/UX Design Essential Training"
                                            />
                                            {errors.title && (
                                                <span className="error-message">Course title is required.</span>
                                            )}
                                        </div>
                                        {/* Short Description */}
                                        <div className="col-12">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                Short Description<span className="text-red">*</span>
                                            </label>
                                            <textarea
                                                {...register("short_description", {required: false})}
                                                placeholder="Short Description"
                                                rows="3"
                                            ></textarea>
                                        </div>
                                        {/* Description */}
                                        <div className="col-12">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                About Course<span className="text-red">*</span>
                                            </label>

                                            <textarea
                                                {...register("description", {required: false})}
                                                placeholder="Description"
                                                rows="4"
                                            ></textarea>
                                        </div>
                                        {/* Course Level */}
                                        <div className="col-md-3">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                Course Level<span className="text-red">*</span>
                                            </label>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                {...register("level", {required: false})}
                                                defaultValue={"BEGINNER"}
                                                fullWidth
                                                size="large"
                                                inputProps={{"aria-label": "Without label"}}
                                            >
                                                {courseLevelArray.map((item, index) => (
                                                    <MenuItem value={item.value} key={index}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        {/* Audio Language */}
                                        <div className="col-md-3">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                Audio Language<span className="text-red">*</span>
                                            </label>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                {...register("audio_language", {required: false})}
                                                defaultValue={"1"}
                                                fullWidth
                                                size="large"
                                                inputProps={{"aria-label": "Without label"}}
                                            >
                                                {audioLanguageArray.map((item, index) => (
                                                    <MenuItem value={item.value} key={index}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        {/* Course Price */}
                                        <div className="col-md-3">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                Course Price<span className="text-red">*</span>
                                            </label>
                                            <br />
                                            <div
                                                style={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                    width: "100%",
                                                }}
                                            >
                                                <div
                                                    className="icon text-dark-1"
                                                    style={{
                                                        position: "absolute",
                                                        left: "8px",
                                                        top: "50%",
                                                        transform: "translateY(-50%)",
                                                    }}
                                                >
                                                    $
                                                </div>
                                                <input
                                                    {...register("course_price", {required: false})}
                                                    type="text"
                                                    placeholder="0"
                                                    style={{paddingLeft: "30px"}}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {/* Is Course Free */}
                                        <div className="col-md-3" style={{display:'flex'}}>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    display: "inline-block",
                                                    width: "100%",
                                                    alignContent:'center',
                                                    paddingTop:'20px'
                                                }}
                                            >
                                                <input
                                                    {...register("is_course_free", {required: false})}
                                                    type="checkbox"
                                                    placeholder="0"
                                                    style={{paddingRight: "30px"}}
                                                    onChange={() => handleFreeCourse()}
                                                />&nbsp;

                                                <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                    Is Course Free
                                                </label>
                                            </div>
                                        </div>
                                        {/* Sale price */}
                                        {!isCourseFree && (
                                            <div className="col-md-3">
                                                <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                    Sale Price<span className="text-red">*</span>
                                                </label>
                                                <br />
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        display: "inline-block",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <div
                                                        className="icon text-dark-1"
                                                        style={{
                                                            position: "absolute",
                                                            left: "8px",
                                                            top: "50%",
                                                            transform: "translateY(-50%)",
                                                        }}
                                                    >
                                                        $
                                                    </div>
                                                    <input
                                                        {...register("sale_price", {required: false})}
                                                        type="text"
                                                        placeholder="0"
                                                        style={{paddingLeft: "30px"}}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                                                        }}
                                                    />
                                                </div>
                                                <span className="grey-message">
                                                    Sale price should be less than course price
                                                </span>
                                            </div>
                                        )}
                                        {/* Course Category */}
                                        <div className="col-md-6">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                Course Category<span className="text-red">*</span>
                                            </label>

                                            <FormControl fullWidth>
                                                {categoryLoading ? (
                                                    <Skeleton variant="rectangular" width={400} height={40} />
                                                ) : (
                                                    <>
                                                        <InputLabel id="demo-simple-select-helper-label">
                                                            Category
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            {...register("category", {required: false})}
                                                            value={category}
                                                            label="Category"
                                                            fullWidth
                                                            onChange={(event) => {
                                                                setValue("category", event.target.value);
                                                                let newSubCategories = Categories.find(
                                                                    (item) =>
                                                                        String(item.id) === String(event.target.value)
                                                                );
                                                                setSubCategories(newSubCategories?.sub_category ?? []);
                                                            }}
                                                            size="large"
                                                            placeholder="Category"
                                                            // inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            {Categories.map((item) => (
                                                                <MenuItem value={item.value} key={item.value}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </>
                                                )}
                                            </FormControl>
                                        </div>
                                        {/* Course Sub Category */}
                                        <div className="col-md-6">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                Course Sub Category<span className="text-red">*</span>
                                            </label>

                                            <FormControl fullWidth>
                                                {categoryLoading ? (
                                                    <Skeleton variant="rectangular" width={400} height={40} />
                                                ) : (
                                                    <>
                                                        <InputLabel id="demo-simple-select-helper-label">
                                                            Sub Category
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-helper-label"
                                                            id="demo-simple-select-helper"
                                                            {...register("sub_category", {required: false})}
                                                            label="Sub Category"
                                                            value={subCategory}
                                                            disabled={!category}
                                                            fullWidth
                                                            size="large"
                                                            placeholder="Sub Category"
                                                        >
                                                            {subCategories.map((item) => (
                                                                <MenuItem value={item.value} key={item.value}>
                                                                    {item.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </>
                                                )}
                                            </FormControl>
                                        </div>
                                        {/* Requirements */}
                                        {/* <div className="col-12">
											<label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
												Requirements<span className="text-red">*</span>
											</label>
											<div
												style={{
													border: "1px solid #ccc",
													minHeight: "200px",
													padding: "5px",
												}}>
												<Editor
													editorState={requirements}
													onEditorStateChange={(editableText) =>
														setValue("requirements", editableText)
													}
													stripPastedStyles={false}
												/>
											</div>
										</div> */}
                                        {/* What will students learn in your course? */}
                                        <div className="col-12">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                What will students learn in your course?
                                                <span className="text-red">*</span>
                                            </label>
                                            <div>
                                                {whatStudentLearn.map((input, index) => (
                                                    <Box
                                                        key={index}
                                                        display="flex"
                                                        alignItems="center"
                                                        mt={1}
                                                        style={{borderColor: "red"}}
                                                    >
                                                        <input
                                                            // {...register("title", { required: true })}
                                                            value={input}
                                                            onChange={(event) =>
                                                                setValue(
                                                                    `what_student_learn[${index}]`,
                                                                    event.target.value
                                                                )
                                                            }
                                                            type="text"
                                                            placeholder={`Points Student learn - ${index + 1}`}
                                                        />
                                                        {whatStudentLearn.length > 1 && (
                                                            <IconButton
                                                                onClick={() => remove(`what_student_learn.${index}`)}
                                                            >
                                                                <Close />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                ))}
                                                <Button
                                                    variant="text"
                                                    size="large"
                                                    style={{
                                                        marginTop: "10px",
                                                        textTransform: "inherit",
                                                    }}
                                                    className="text-purple-1 fw-500 mt-10"
                                                    onClick={() => append("")}
                                                >
                                                    + Add points
                                                </Button>
                                            </div>
                                        </div>

                                        {/* Material Included in your course? */}
                                        <div className="col-12">
                                            <label className="text-16 lh-1 fw-500 text-dark-1 mb-10">
                                                Material Included in your course
                                                <span className="text-red">*</span>
                                            </label>
                                            <div>
                                                {materialIncluded.map((input, index) => (
                                                    <Box
                                                        key={index}
                                                        display="flex"
                                                        alignItems="center"
                                                        mt={1}
                                                        style={{borderColor: "red"}}
                                                    >
                                                        <input
                                                            // {...register("title", { required: true })}
                                                            value={input}
                                                            onChange={(event) =>
                                                                setValue(
                                                                    `material_included[${index}]`,
                                                                    event.target.value
                                                                )
                                                            }
                                                            type="text"
                                                            placeholder={`Material Included - ${index + 1}`}
                                                        />
                                                        {materialIncluded.length > 1 && (
                                                            <IconButton
                                                                onClick={() =>
                                                                    removeMaterial(`material_included.${index}`)
                                                                }
                                                            >
                                                                <Close />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                ))}
                                                <Button
                                                    variant="text"
                                                    size="large"
                                                    style={{
                                                        marginTop: "10px",
                                                        textTransform: "inherit",
                                                    }}
                                                    className="text-purple-1 fw-500 mt-10"
                                                    onClick={() => appendMaterial("")}
                                                >
                                                    + Add points
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="pt-10 col-md-12 d-flex justify-content-end">
                                            <div>
                                                <button
                                                    type="submit"
                                                    onClick={handleSubmit(onSubmitBasicDetails)}
                                                    disabled={loading}
                                                    className="button h-50 px-25 -dark-1 -dark-button-white text-white"
                                                >
                                                    {loading
                                                        ? "Loading..."
                                                        : course.id
                                                        ? "Update Course"
                                                        : "Save & Next"}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    {/* Basic Information Ends */}

                    {/* Upload Thumbnail starts*/}
                    {course.id ? (
                        <Accordion expanded={expanded === "panel2"} onChange={handleAccordionChange("panel2")}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <div sx={{width: "50%", flexShrink: 0}}>
                                    <div className="d-flex items-center px-30">
                                        <h2 className="text-17 lh-1 fw-500">Upload Thumbnail</h2>
                                    </div>
                                </div>
                                {/* {course.id && (
									<Typography sx={{ color: "green" }}>
										Working on {course.title}
									</Typography>
								)} */}
                            </AccordionSummary>
                            <AccordionDetails>
                                <UploadCourseThumbnail
                                    courseThumbnailImage={courseThumbnailImage}
                                    setCourseThumbnailImage={setCourseThumbnailImage}
                                    courseThumbnailVideo={courseThumbnailVideo}
                                    setCourseThumbnailVideo={setCourseThumbnailVideo}
                                    courseThumbnailImageURL={courseThumbnailImageURL}
                                    setCourseThumbnailImageURL={setCourseThumbnailImageURL}
                                    courseThumbnailVideoURL={courseThumbnailVideoURL}
                                    setCourseThumbnailVideoURL={setCourseThumbnailVideoURL}
                                    handleUploadFiles={handleUploadFiles}
                                    loading={imageUploadLoading || videoUploadLoading}
                                    uploadedPath={uploadedPath}
                                    setUploadedPath={setUploadedPath}
                                    isImagePresent={isImagePresent}
                                    isVideoPresent={isVideoPresent}
                                    setFinalThumbnailStatus={setFinalThumbnailStatus}
                                    handleImageDelete={handleImageDelete}
                                    handleVideoDelete={handleVideoDelete}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ) : null}
                    {/* Upload Thumbnail ends */}

                    {/* Curriculum Starts */}
                    {course.id ? (
                        <Accordion expanded={expanded === "panel3"} onChange={handleAccordionChange("panel3")}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <div sx={{width: "50%", flexShrink: 0}}>
                                    <div className="d-flex items-center px-30">
                                        <h2 className="text-17 lh-1 fw-500">Curriculum Management</h2>
                                    </div>
                                </div>
                                {showAutoSave && <Typography sx={{color: "#198500"}}>Auto Saving</Typography>}
                            </AccordionSummary>
                            <AccordionDetails>
                                <Curriculum
                                    courseId={course.id}
                                    course_slug_name={course?.slug_name}
                                    handleShowAutoText={handleShowAutoText}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ) : null}
                    {/* Curriculum ends */}
                </div>
            </div>
            <LoggedInFooter />
        </div>
    );
}
