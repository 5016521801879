import React, { useCallback, useEffect, useState } from "react";
import {
	Avatar,
	Chip,
	ListItem,
	ListItemText,
	Pagination,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
	commonDashboardHeading,
	commonDashboardSubHeading,
} from "../../../../components/common/DashboardElements";
import { LoggedInFooter } from "../../../../components/layout/footers/LoggedInFooter";
import { debounce } from "lodash";
import {
	getSuperAllCourseFilterListAction,
	getSuperAllCourses,
} from "../../../../redux/action/superAdminActions/superCoursesActions";
import { PRIMARY_COLOR } from "../../../../config/config";
import SuperAllCoursesFilterAndSortingArea from "./SuperAllCoursesFilterAndSortingArea";
import { Link } from "react-router-dom";
import { CustomNoDataComponent } from "../../../../components/common/CustomNoDataComponent";

export default function SuperAllCourses() {
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const [sortBy, setSortBy] = useState([]);

	const [filterCategory, setFilterCategory] = useState(["0"]);
	const [filterSubCategory, setFilterSubCategory] = useState(["0"]);
	const [filterRating, setFilterRating] = useState(0);
	const [filterInstructor, setFilterInstructor] = useState(["0"]);
	const [filterLevel, setFilterLevel] = useState(["0"]);
	const [filterLanguage, setFilterLanguage] = useState(["0"]);
	const [filterDuration, setFilterDuration] = useState(["0"]);

	const [subCategoryList, setSubCategoryList] = useState([]);

	const isMobile = useMediaQuery("(max-width:600px)");
	const isTablet = useMediaQuery("(max-width:690px)");

	const {
		data: AllCourses,
		isLoading,
		isSuccess,
		meta_data,
	} = useSelector((state) => state.superAdmin.superCourses.allCourses);

	useEffect(() => {
		if (!localStorage.getItem("beauty_super_key")) {
			window.location.replace("login");
		}
	}, []);

	useEffect(() => {
		dispatch(getSuperAllCourses());
		dispatch(getSuperAllCourseFilterListAction());
	}, [dispatch, isSuccess]);

	const bodyForGetApi = () => {
		let body = {
			page: meta_data?.current ?? 1,
			search: search,
			ordering: sortBy[0],
			category: Array.isArray(filterCategory)
				? filterCategory.join(",")
				: filterCategory,
			subcategory: Array.isArray(filterSubCategory)
				? filterSubCategory.join(",")
				: filterSubCategory,
			rating: Array.isArray(filterRating)
				? filterRating.join(",")
				: filterRating,
			seller: Array.isArray(filterInstructor)
				? filterInstructor.join(",")
				: filterInstructor,
			level: Array.isArray(filterLevel) ? filterLevel.join(",") : filterLevel,
			audio_language: Array.isArray(filterLanguage)
				? filterLanguage.join(",")
				: filterLanguage,
			duration: Array.isArray(filterDuration)
				? filterDuration.join(",")
				: filterDuration,
		};
		return body;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchDebounced = useCallback(
		debounce((value, tempBody) => {
			let body = {
				search: value,
				page: 1,
				ordering: tempBody.ordering,
				category: tempBody.category,
				subcategory: tempBody.subcategory,
				rating: tempBody.rating,
				seller: tempBody.seller,
				level: tempBody.level,
				audio_language: tempBody.audio_language,
				duration: tempBody.duration,
			};
			dispatch(getSuperAllCourses(body));
		}, 500),
		[debounce]
	);

	const handleSearchChange = (e) => {
		const { value } = e.target;
		setSearch(value);
		let tempBody = bodyForGetApi();
		handleSearchDebounced(value, tempBody);
	};

	const handleSorting = (item) => {
		const tempBody = bodyForGetApi();
		const body = {
			...tempBody,
			ordering: item,
		};
		dispatch(getSuperAllCourses(body));
	};

	const handleApplyFilters = () => {
		const tempBody = bodyForGetApi();
		const body = {
			...tempBody,
		};
		dispatch(getSuperAllCourses(body));
	};

	const handleResetFilters = () => {
		let body = {
			search: search,
			ordering: sortBy[0],
		};
		dispatch(getSuperAllCourses(body));
	};

	const handlePageChange = (value) => {
		let tempBody = bodyForGetApi();
		let body = {
			...tempBody,
			page: value,
		};
		dispatch(getSuperAllCourses(body));
	};

	const getStatusChip = (status) => {
		let chipColor;
		switch (status) {
			case "DRAFT":
				chipColor = "error";
				break;
			case "PUBLISHED":
				chipColor = "success";
				break;
			case "UN_PUBLISHED":
				chipColor = "warning";
				break;
			default:
				return null;
		}
		return (
			<Chip size="small" label={status} variant="outlined" color={chipColor} />
		);
	};

	const fullColumns = [
		{
			field: "image",
			width: 150,
			sortable: false,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">
					{"Image"}
				</Typography>
			),
			renderCell: (params) => {
				return (
					<div>
						<Avatar
							variant="square"
							style={{ width: "100px", height: "60px", objectFit: "contain" }}
							src={params.row.course_thumbnail_image}
							alt="course-thumbnail"
						/>
					</div>
				);
			},
		},
		{
			field: "name",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">
					{"Course"}
				</Typography>
			),
			renderCell: (params) => {
				return (
					<Link to={`${params?.row.slug_name}`} className="col text-purple-1">
						<b>{params?.row?.title ?? "--"}</b>
					</Link>
				);
			},
		},
		{
			field: "instructor",
			sortable: false,
			flex: 0.5,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">
					{"Instructor"}
				</Typography>
			),
			renderCell: (params) => {
				return (
					<ListItem>
						<ListItemText
							primary={
								<div>
									{params?.row?.seller_obj?.user_first_name ?? "--"}{" "}
									{params?.row?.seller_obj?.user_last_name ?? "--"}
								</div>
							}
							secondary={params?.row?.seller ?? "--"}
						/>
					</ListItem>
				);
			},
		},
		{
			field: "numbers",
			sortable: false,
			flex: 0.5,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">
					{"Count"}
				</Typography>
			),
			renderCell: (params) => {
				return (
					<div>
						<ListItem>
							<ListItemText
								primary={`Chapters: ${params?.row?.chapters_count ?? 0}`}
								secondary={`Lessons: ${params?.row?.lesson_count ?? 0}`}
							/>
						</ListItem>
					</div>
				);
			},
		},
		{
			field: "price",
			sortable: false,
			flex: 0.5,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">
					{"Price"}
				</Typography>
			),
			renderCell: (params) => {
				return (
					<div>
						<ListItem>
							<ListItemText
								primaryTypographyProps={{ style: { color: PRIMARY_COLOR } }}
								primary={`Sale: ${!params.row.is_course_free ? "$" + params.row.sale_price : 'Free'}`}
								secondary={`Course: $${params.row.course_price}`}
							/>
						</ListItem>
					</div>
				);
			},
		},
		{
			field: "status",
			sortable: false,
			flex: 0.5,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">
					{"Status"}
				</Typography>
			),
			renderCell: (params) => {
				return (
					<>{getStatusChip(params?.row?.course_status)}</>
				);
			},
		},
	];

	// Reduced columns for mobile and tablet views
	const reducedColumns = [
		{
			field: "name",
			width: 200,
			sortable: false,
			flex: 1,
			renderHeader: () => (
				<Typography variant="h6" className="text-purple-1">
					{"Course"}
				</Typography>
			),
			renderCell: (params) => <b>{params?.row?.title ?? "--"}</b>,
		},
	];

	const columns = isMobile || isTablet ? reducedColumns : fullColumns;

	const propsForFilterAndSortingComponent = {
		loading: isLoading,
		sortBy,
		setSortBy,
		handleSorting,
		filterCategory,
		setFilterCategory,
		filterSubCategory,
		setFilterSubCategory,
		filterRating,
		setFilterRating,
		filterInstructor,
		setFilterInstructor,
		filterLevel,
		setFilterLevel,
		filterLanguage,
		setFilterLanguage,
		filterDuration,
		setFilterDuration,
		subCategoryList,
		setSubCategoryList,
		handleApplyFilters,
		handleResetFilters,
	};

	return (
		<div className="dashboard__main">
			<div
				className="dashboard__content bg-light-4"
				style={{ paddingBottom: "0px" }}>
				<div className="row pb-30">
					<div className="col-auto">
						{commonDashboardHeading("All Courses")}
						{commonDashboardSubHeading("Welcome to the all Courses Section.")}
					</div>
					<div
						className={`col-auto ${
							isMobile || isTablet ? "me-auto" : "ms-auto"
						}`}>
						<div
							style={{
								position: "relative",
								display: "inline-block",
								width: "auto",
							}}>
							<div
								className="icon text-dark-1"
								style={{
									position: "absolute",
									left: "5px",
									top: "56%",
									transform: "translateY(-50%)",
								}}>
								<i className="icon-search text-light-1 text-18"></i>
							</div>
							<input
								type="text"
								placeholder="Type to search..."
								style={{
									paddingLeft: "30px",
									paddingTop: "10px",
									paddingBottom: "10px",
									border: "1px solid grey",
									borderRadius: "20px",
									width: "300px",
								}}
								value={search}
								onChange={(e) => handleSearchChange(e)}
							/>
						</div>
					</div>
				</div>

				<SuperAllCoursesFilterAndSortingArea
					{...propsForFilterAndSortingComponent}
				/>

				{/*Table Starts here */}
				<div
					style={{
						width: "100%",
						overflowY: "scroll",
						height: "calc(98vh - 300px)",
					}}>
					<DataGrid
						rows={AllCourses}
						columns={columns}
						rowHeight={100}
						showCellVerticalBorder
						disableColumnMenu
						disableRowSelectionOnClick={true}
						disableColumnSelector
						disableFocus
						loading={isLoading}
						sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: "#E5F0FD",
							},
						}}
						hideFooter
						slots={{
							noRowsOverlay: CustomNoDataComponent
						}}
					/>
				</div>
				{/* Pagination Starts here */}
				<div className="row justify-center pt-30 lg:pt-50">
					<div className="col-auto">
						<div className="pagination -buttons d-flex justify-content-center">
							<Pagination
								count={meta_data?.total_pages ?? 0}
								page={meta_data?.current ?? 1}
								onChange={(event, value) => handlePageChange(value)}
								size="large"
								sx={{
									ul: {
										"& .Mui-selected": {
											background: "#6440FB",
											color: "white",
										},
									},
								}}
								disabled={isLoading}
							/>
						</div>
					</div>
				</div>
				{/* Pagination ends here  */}
			</div>
			<LoggedInFooter />
		</div>
	);
}
