import React, { useEffect, useState } from "react";
import MetaComponent from "../MetaComponent";
import Preloader from "../Preloader";
import Header from "../../layout/headers/Header";
import { COMPANY_NAME, Stripe_PUBLIC_API_KEY } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
	EmbeddedCheckoutProvider,
	EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { Empty, Spin } from "antd";
import { addFreeEnrolledCoursesAction, getCheckOutLinkActions } from "../../../redux/action/checkoutActions.js/checkoutActions";
import { useSnackbar } from "notistack";
import { getCourseFromLocalCartAction, getCourseInCartAction } from "../../../redux/action/cartActions/cartActions";

const metadata = {
	title: `Course-Checkout  | ${COMPANY_NAME}`,
	description: `Elevate your e-learning content with ${COMPANY_NAME}, the most impressive LMS template for online courses, education and LMS platforms.`,
};

const stripePromise = loadStripe(Stripe_PUBLIC_API_KEY);

export default function CourseCheckoutPage() {
	const [totalPrice, setTotalPrice] = useState(0);
	const [clientSecret, setClientSecret] = useState(null);
	const [freeCourseLoading, setFreeCourseLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const loggedInUser = localStorage.getItem("loggedIn_user") ?? "{}";
	const parsedLoggedInData = JSON.parse(loggedInUser);

	const { data: courseCart, isLoading: isCartLoading } = useSelector(
		(state) => state.cart.courseCart
	);
	const onlyFreeCourses = Number(totalPrice) === 0;

	useEffect(() => {
		let totalSalePrice = 0;
		courseCart?.length &&
			courseCart.forEach((course) => {
				totalSalePrice += parseFloat(course.sale_price);
			});
		setTotalPrice(totalSalePrice);
	}, [courseCart]);

	useEffect(() => {
		if (courseCart?.length && !onlyFreeCourses) {
			let courseIds = courseCart.map((item) => item.id);
			let body ={
                products : courseIds,
				domain : window.location.origin
            }
			dispatch(
				getCheckOutLinkActions(body, {
					onSuccess: (success) => {
                        let client_sec = success?.data?.client_secret ?? ''
                        setClientSecret(client_sec)
					},
					onError: (error) => {
						let errorMessage = String(error?.message);
						enqueueSnackbar(errorMessage, { variant: "error" });
                        setClientSecret('')
					},
					onLoading: (loading) => {
						enqueueSnackbar("Setting up your payment system...", { variant: "info" });
					},
				})
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, courseCart,onlyFreeCourses]);

	const options = { clientSecret };

	const handleEmptyCart = async () => {
		localStorage.removeItem("beauty_cart_items");
		if (parsedLoggedInData.id) {
			// Dispatch action to get courses in cart if the user is logged in
			dispatch(getCourseInCartAction());
			navigate("/");
		} else {
			// Dispatch action to get courses from local cart if the user is not logged in
			dispatch(getCourseFromLocalCartAction());
		}
	};

	const addFreeCourses = () => {
		setFreeCourseLoading(true)
		let body = {
			course : courseCart.map((item) => String(item.id))
		}
		dispatch(
			addFreeEnrolledCoursesAction(body, {
				onSuccess: () => {
					setFreeCourseLoading(false)
					handleEmptyCart()
					enqueueSnackbar("Free courses added to your account", { variant: "success" });
				},
				onError: (error) => {
					setFreeCourseLoading(false)
					let errorMessage = String(error?.message);
					enqueueSnackbar(errorMessage, { variant: "error" });
				},
				onLoading: (loading) => {
					setFreeCourseLoading(true)
					enqueueSnackbar("Please wait...", { variant: "info" });
				},
			})
		);
	}

	return (
		<div className="main-content  ">
			<MetaComponent meta={metadata} />
			<Preloader />

			<Header />
			<div className="content-wrapper js-content-wrapper overflow-hidden">
				<>
					<section className="page-header -type-1 mt-50">
						<div className="container">
							<div className="page-header__content">
								<div className="row justify-center text-center">
									<div className="col-auto">
										<div>
											<h1 className="page-header__title">Course Checkout</h1>
										</div>

										<div>
											<p className="page-header__text">
												You're one step closer to unlocking a world of knowledge
												and mastering new beauty skills!
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="layout-pt-md layout-pb-lg">
						<Spin spinning={isCartLoading}>
							<div className="container">
								{courseCart?.length ? (
									<div className="row y-gap-50" style={{ display: "flex",justifyContent: "center",textAlign: "center"}}>
										{!onlyFreeCourses && (
											<div className="col-lg-8">
												<div className="shopCheckout-form">
													{clientSecret && stripePromise && (
														<EmbeddedCheckoutProvider
															stripe={stripePromise}
															options={options}>
															<EmbeddedCheckout />
														</EmbeddedCheckoutProvider>
													)}
												</div>
											</div>
										)}

										{/* Your Order Details Section Starts */}
										<div className="col-lg-4">
											<div className="">
												<div className="pt-30 pb-15 bg-white border-light rounded-8 bg-light-4">
													<h5 className="px-30 text-20 fw-500">Your order</h5>

													<div className="d-flex justify-between px-30 mt-25">
														<div className="py-15 fw-500 text-dark-1">
															Course
														</div>
														<div className="py-15 fw-500 text-dark-1">
															Subtotal
														</div>
													</div>

													{courseCart.map((elm, i) => (
														<div
															key={i}
															className={`d-flex justify-between ${
																i === 0 ? "border-top-dark" : ""
															}  px-30`}>
															<div className="py-15 text-grey text-left">
																<Link
																	className="linkCustom"
																	to={`/courses/${elm.slug_name}`}>
																	{elm.title}{" "}
																</Link>
															</div>
															<div className="py-15 text-grey">
																${elm?.sale_price ?? "00"}
															</div>
														</div>
													))}

													{/* <div className="d-flex justify-between border-top-dark px-30">
														<div className="py-15 fw-500">Subtotal</div>
														<div className="py-15 fw-500">
															${totalPrice.toFixed(2)}
														</div>
													</div> */}

													<div className="d-flex justify-between border-top-dark px-30">
														<div className="py-15 fw-500 text-dark-1">
															Total
														</div>
														<div className="py-15 fw-500 text-dark-1">
															${totalPrice.toFixed(2)}
														</div>
													</div>
												</div>

												{onlyFreeCourses && <div className="mt-30" style={{display: "flex",justifyContent: "center"}}>
													<Spin spinning={freeCourseLoading}>
													<button className="button -md -purple-1 text-white" onClick={()=>addFreeCourses()}>
														Place Order
													</button>
													</Spin>
												</div>}
											</div>
										</div>
										{/* Your Order Details Section Ends */}
									</div>
								) : (
									<Empty
										description={
											"There is nothing to purchase. Try adding few things in your cart..."
										}
									/>
								)}
							</div>
						</Spin>
					</section>
				</>
			</div>
		</div>
	);
}
