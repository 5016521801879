import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	Chip,
	LinearProgress,
	Pagination,
	useMediaQuery,
} from "@mui/material";
import { Empty, Spin } from "antd";
import { getSellerPaymentHistory } from "./../../../../../../redux/action/billingActions/billingActions";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faClock,
	faMoneyBillTransfer,
	faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { LoggedInFooter } from "../../../../../../components/layout/footers/LoggedInFooter";

export default function BillingList() {
	const dispatch = useDispatch();

	const isMobile = useMediaQuery("(max-width:600px)");
	const isDesktop = useMediaQuery("(min-width:601px)");

	const {
		data: SellerBilling,
		isLoading,
		meta_data,
	} = useSelector((state) => state.billing.sellerPaymentHistory);

	useEffect(() => {
		let body = {
			page: 1,
		};
		dispatch(getSellerPaymentHistory(body));
	}, [dispatch]);

	const getStatusChip = (item) => {
		let chipColor = "info";
		let label;
		if (item?.event_type === "CLEARING") {
			chipColor = "info";
			label = "Pending";
		} else {
			switch (item?.status) {
				case "SUCCESS":
					chipColor = "success";
					label = "Success";
					break;
				case "INITIATE":
					chipColor = "info";
					label = "Processing";
					break;
				case "FAILURE":
					chipColor = "error";
					label = "Failure";
					break;
				case "DIAGNOSE":
					chipColor = "error";
					label = "Report to Admin";
					break;
				default:
					return;
			}
		}
		return (
			<Chip size="small" label={label} variant="outlined" color={chipColor} />
		);
	};

	const handlePageChange = (value) => {
		let body = {
			page: value,
		};
		dispatch(getSellerPaymentHistory(body));
	};

	const getTransactionType = (item) => {
		let text = "";
		let textColor = "text-dark-1";
		let showProgress = false;
		let icon = "";
		let today = moment();
		let difference = moment(item?.earn_date ?? "--").diff(today);
		let daysDifference = Math.ceil(moment.duration(difference).asDays());

		let totalDays = moment(item?.earn_date).diff(item?.created_at) ?? 0;
		let totalDaysDifference =
			Math.ceil(moment.duration(totalDays).asDays()) ?? 0;

		let percentageValue = (daysDifference / totalDaysDifference) * 100;

		switch (item.event_type) {
			case "CLEARING":
				text = "Clearing";
				textColor = "text-dark-1";
				showProgress = true;
				icon = faClock;
				break;
			case "SELLER_WITHDRAW":
				text = "Money Withdraw";
				textColor = "text-error-2";
				showProgress = false;
				icon = faMoneyBillTransfer;
				break;
			case "EARNED":
				text = "Earned";
				textColor = "text-green-5";
				showProgress = false;
				icon = faMoneyCheckDollar;
				break;
			case "WITHDRAW_PROCESSING":
				text = "Withdraw Processing";
				textColor = "text-blue-5";
				showProgress = false;
				icon = faMoneyCheckDollar;
				break;
			case "EDUCATOR_BUYED":
				text = "Buyed Course";
				textColor = "text-green-5";
				showProgress = false;
				icon = faMoneyCheckDollar;
				break;
			default:
				text = "";
		}
		return (
			<div className="d-flex">
				<div>
					<FontAwesomeIcon icon={icon} />
				</div>{" "}
				&emsp;
				<div>
					<div className={`${textColor} lh-12 fw-500`}>{text}</div>
					{showProgress && (
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Box sx={{ width: "100%", mt: 1 }}>
								<LinearProgress
									color="success"
									variant="determinate"
									value={percentageValue ?? 0}
								/>
							</Box>
						</Box>
					)}
				</div>
			</div>
		);
	};

	const getDateDifference = (item) => {
		switch (item.event_type) {
			case "CLEARING": {
				let today = moment();
				let difference = moment(item?.earn_date ?? "--").diff(today);
				let daysDifference = moment.duration(difference).asDays();
				return (
					<div className="text-error-2">{Math.ceil(daysDifference)} days left</div>
				);
			}
			case "SELLER_WITHDRAW":
			case "EARNED":
			default:
				return <>{moment(item?.earn_date ?? "--").format("MMM Do YYYY")}</>;
		}
	};

	const getAmount = (item) => {
		let text = "";
		let textColor = "text-dark-1";
		switch (item.event_type) {
			case "CLEARING":
				text = "";
				textColor = "text-dark-1";
				break;
			case "SELLER_WITHDRAW":
				text = "-";
				textColor = "text-error-2";
				break;
			case "EARNED":
				text = "+";
				textColor = "text-green-5";
				break;
			default:
				text = "";
		}
		return (
			<>
				<div className={`text-18 lh-1 ${textColor} fw-500 text-center`}>
					{text} ${item?.amount ?? 0}
				</div>
			</>
		);
	};

	return (
		<div className="dashboard__main">
			<div className="dashboard__content bg-light-4">
				<div className="row pb-50 mb-10 mt-50">
					<div className="col-auto">
						<h1 className="text-24 lh-1 fw-700">Transaction History</h1>
						<div className="mt-10">
							Welcome to the Transaction History Section.
						</div>
					</div>
				</div>
				{/*Table Starts here */}
				<div className="px-30 py-10 bg-light-7 -dark-bg-dark-2 rounded-8">
					<div className="row x-gap-10">
						{isMobile && (
							<>
								<div className="col-8">
									<div className="text-purple-1 text-left">Title</div>
								</div>
								<div className="col-4">
									<div className="text-purple-1 text-left">Status</div>
								</div>
							</>
						)}
						{isDesktop && (
							<>
								<div className="col-lg-5">
									<div className="text-purple-1 text-left">
										Transaction Type
									</div>
								</div>
								<div className="col-lg-3">
									<div className="text-purple-1 text-left">Date</div>
								</div>
								<div className="col-lg-2">
									<div className="text-purple-1 text-center">Amount</div>
								</div>
								<div className="col-lg-2">
									<div className="text-purple-1 text-center">Status</div>
								</div>
							</>
						)}
					</div>
				</div>

				<Spin spinning={isLoading}>
					<div
						style={{
							width: "100%",
							overflowY: "scroll",
							height: "calc(100vh - 370px)",
						}}>
						{SellerBilling?.length ? (
							SellerBilling.map((elm, i) => (
								<div
									key={i}
									className={`px-30 ${
										elm?.status === "DIAGNOSE"
											? "border-error"
											: "border-bottom-light"
									}`}>
									<div className="row x-gap-10 items-center py-15">
										{isMobile && (
											<>
												<div className="col-8">
													<div className="d-flex items-center">
														<div className="ml-10">
															{getTransactionType(elm)}
														</div>
													</div>
												</div>
												<div className="col-4">{getStatusChip(elm)}</div>
											</>
										)}
										{isDesktop && (
											<>
												<div className="col-lg-5">
													<div className="d-flex items-center">
														<div className="ml-10">
															{getTransactionType(elm)}
														</div>
													</div>
												</div>

												<div className="col-lg-3">
													<div className="d-flex items-center">
														<div className="ml-10">
															<div>{getDateDifference(elm)}</div>
														</div>
													</div>
												</div>

												<div className="col-lg-2">{getAmount(elm)}</div>
												<div className="col-lg-2 text-center">
													{getStatusChip(elm)}
												</div>
											</>
										)}
									</div>
								</div>
							))
						) : (
							<div style={{ marginTop: "10%" }}>
								<Empty description={"Payment not added yet..."} />
							</div>
						)}
					</div>
				</Spin>

				{/* Table ends here  */}
			</div>
			<div className="pagination -buttons d-flex justify-content-center">
				<Pagination
					count={meta_data?.total_pages ?? 0}
					page={meta_data?.current ?? 1}
					onChange={(event, value) => handlePageChange(value)}
					size="large"
					sx={{
						ul: {
							"& .Mui-selected": {
								background: "#6440FB",
								color: "white",
							},
						},
					}}
					disabled={isLoading}
				/>
			</div>
			<LoggedInFooter/>
		</div>
	);
}
